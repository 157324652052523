/* -------------------------- Design imports start -------------------------- */
import { Alert, Box, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material"
import { CloseRounded, Delete, Download, FileOpenRounded, ViewInAr } from "@mui/icons-material"
import { toast } from "react-toastify"
/* --------------------------- Design imports end --------------------------- */




/* ------------------------ Functional imports start ------------------------ */
import { useTranslation } from "react-i18next"
import { FileInfo, HTTPMethod } from "../../utils/types"
import { createFileInfo, deleteFile, fetchFileList, formatBytes, handleAPICallV1, isPrivileged } from "../../utils/functions"
import LogTool from "../../logger/logTools"
import { useUserContext } from "../../utils/context"
import { useState } from "react"
import DeleteModal from "./DeleteModal"
import StlViewerModal from "../../features/StlViewer/StlViewerModal"
import PdfViewerModal from "../../features/PdfHandling/PdfModal"
import FileViewerModal from "./FileViewerModal"
import Button from "../Button"
/* ------------------------- Functional imports end ------------------------- */


type Props = {
  files: {
    state: FileInfo[],
    setState: (state: FileInfo[]) => void // (state: FileInfo[] | ((prevState: FileInfo[]) => FileInfo[])) => void
  }
  // callbacks - you probably don't need them, but in case you do they are there
  onRemoveLocalFile?: (file: FileInfo) => void
  onMarkAsDeleted?: (file: FileInfo) => void
  onDownloadFile?: (file: FileInfo) => void

  enableReplacementModal?: boolean
  sx?: React.CSSProperties
}



/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export default function FilesTable(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    files,
    onRemoveLocalFile = () => null,
    onMarkAsDeleted = () => null,
    onDownloadFile = () => null,
    enableReplacementModal=false,
    sx
  } = props
  const log = new LogTool({context: 'FilesTable', enable: true, logLevel: 'debug'})
  const { t } = useTranslation()
  const { user } = useUserContext()
  /* --------------------------- Non state data end --------------------------- */



  /* ---------------------------- Flag states start --------------------------- */
  const [openStlViewer, setOpenStlViewer] = useState(false)
  const [openFileViewer, setOpenFileViewer] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */



  /* ---------------------------- Data states start --------------------------- */
  const [selectedFile, setSelectedFile] = useState<FileInfo>()
  /* ----------------------------- Data states end ---------------------------- */



  /* ------------------------------ Effects start ----------------------------- */
  // some useEffects...
  // !! Note!!: you should try to avoid setting state in a useEffect as this renders
  // the component twice. For more info read: https://react.dev/learn/you-might-not-need-an-effect
  /* ------------------------------- Effects end ------------------------------ */



  /* ------------------------- Utility functions start ------------------------ */
  // async function refetchRenderedFiles() {
  //   // determine the fetch url
  //   const file = files[0]
  //   let fetchUrl = ''
  //   if('article' in file) {
  //     fetchUrl = file.article! + 'files/'
  //   }
  //   if('contract' in file) {
  //     fetchUrl = file.contract! + 'files/'
  //   }
  //   if('request' in file) {
  //     fetchUrl = file.request! + 'files/'
  //   }
  //   if('guestRequest' in file) {
  //     fetchUrl = file.guestRequest! + 'files/'
  //   }
  //   if(fetchUrl === '') {
  //     throw new Error(
  //       'Failed to automatically determine the fetch url for the rendered files in the FilesTable. '
  //       + 'You might need to add another test-case to the fetch url detection.'
  //     )
  //   }

  //   fetchFileList({
  //     url: fetchUrl,
  //     onSuccess: (files: FileInfo[]) => {
  //       setRenderedFiles(files)
  //     },
  //     onError: (error: any) => {
  //       toast.error(t('files:feedback.error.updatingFilesInTable'))
  //     }
  //   })
  // }
  // async function updateFileInTable(file: FileInfo) {
  //   log.info('Refetching file', file.name!)
  //   const [response, error] = await handleAPICallV1(
  //     HTTPMethod.GET,
  //     file.self!,
  //   )

  //   if(!error && response) {
  //     log.info('Success refetching file', file.name!)
  //     const updatedFile = createFileInfo(response)
  //     setRenderedFiles((prev: FileInfo[]) => {
  //       return prev.map(file => {
  //         if(file.self! === updatedFile.self!) {
  //           return updatedFile
  //         } else return file
  //       })
  //     })
  //   } else {
  //     if('status' in error && error.status === 404) {
  //       log.info('File', file.name, 'was deleted. Removing it from rendered files list.')
  //       // the file was deleted and is therefore not available anymore
  //       setRenderedFiles((prev: FileInfo[]) => {
  //         return prev.filter(
  //           (storedFile: FileInfo) => storedFile.self! !== file.self!
  //         )
  //       })
  //     } else {
  //       log.error('Error refetching file', file.name!, error)
  //       toast.error(t('something'))
  //     }
  //   }
  // }
  /* -------------------------- Utility functions end ------------------------- */



  /* ------------------------ Callback functions start ------------------------ */
  const handleDownloadFile = async (file: FileInfo) => {
    try {
      const link = document.createElement('a')
      link.href = file.document!
      link.setAttribute('download', file.name! || '')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)

      onDownloadFile(file)
      toast.success(t('common:feedback.success.downloadStarted') || '')
    } catch (error) {
      console.error(error)
      toast.error(t('common:feedback.error.downloadFailed') || '')
    }
  }

  const handleReplaceFile = async (replacedFile: FileInfo, replacementFile: FileInfo) => {
    // update the file list
    files.setState(
      files.state.map(file => {
        if(file.self === replacedFile.self) return replacementFile
        else return file
      })
    )
  }

  /**
   * Sets markedAsDeleted of the provided file to true. This is currently only
   * supported for files known by the backend (not local files).
   * @param file
   */
  const handleMarkAsDeleted = (file: FileInfo) => {
    const updatedFiles = [...files.state]
    const i = updatedFiles.findIndex(storedFile => storedFile.self! === file.self!)
    updatedFiles[i].markedAsDeleted = true
    files.setState(updatedFiles)

    onMarkAsDeleted(file)
  }

  /**
   * Removes the provided local file from files.state.
   * @param file
   */
  const handleRemoveLocalFile = (file: FileInfo) => {
    files.setState(files.state.filter(
      storedFile => storedFile.name !== file.name
    ))

    onRemoveLocalFile(file)
  }

  const handleOpenStlViewer = (file: FileInfo) => {
    setSelectedFile(file)
    setOpenStlViewer(true)
  }
  /* ------------------------- Callback functions end ------------------------- */


  log.debug(
    'files.state ->', files.state,
  )



  /* ------------------------- Render constants start ------------------------- */
  const renderFileEntry = (file: FileInfo, key: number | string) => {
    const isLocalFile = typeof file.self !== 'string'

    return (
      <TableRow key={key}>
        <TableCell>{file.name}</TableCell>
        <TableCell>{formatBytes(file.size as number)}</TableCell>
        <TableCell>{file.storedAt?.toLocaleDateString()}</TableCell>
        <TableCell align="left">
          <Stack direction="row" spacing={1}>
            {isLocalFile && <>
              <Tooltip title={t('common:interaction.button.remove')}>
                <IconButton
                  onClick={() => handleRemoveLocalFile(file)}
                >
                  <CloseRounded />
                </IconButton>
              </Tooltip>
            </>}
            {!isLocalFile && <>
              {/* only files that are stored in the backend can be downloaded */}
              <Tooltip title={t('common:content.label.download') || ''}>
                <IconButton onClick={() => handleDownloadFile(file)}>
                  <Download />
                </IconButton>
              </Tooltip>

              {/* only files that are known by the backend can be deleted */}
              <Tooltip title={t('common:content.label.delete') || ''}>
                <IconButton onClick={() => handleMarkAsDeleted(file)}>
                  <Delete />
                </IconButton>
              </Tooltip>
              {(file.document?.includes(".stl") || file.document?.includes(".STL")) && (
              <Tooltip title={t('common:content.label.showStl') || ''}>
                <IconButton
                  onClick={handleOpenStlViewer}
                >
                  <ViewInAr />
                </IconButton>
              </Tooltip>
              )}
              {file.name?.match(/(?:.pdf|.jpg|.jpeg|.png)$/i) &&
                <Tooltip title={t('common:content.label.showPdf') || ''}>
                  <IconButton
                    onClick={() => {
                      setSelectedFile(file)
                      setOpenFileViewer(true)
                    }}
                  >
                    <FileOpenRounded />
                  </IconButton>
                </Tooltip>
              }
            </>}
          </Stack>
        </TableCell>
      </TableRow>
    )
  }
  /* -------------------------- Render constants end -------------------------- */



  /* ------------------------ Pre render actions start ------------------------ */

  /* ------------------------- Pre render actions end ------------------------- */



  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  if(!Array.isArray(files.state) || files.state.length === 0) {
    return (
      <Alert severity="info">
        {t('common:content.label.noFilesFound') || ''}
      </Alert>
    )
  }

  return (
    <Box sx={{...sx}}>
      <TableContainer>
        <Table>
          <TableHead className="table-header">
            <TableRow>
              <TableCell>{t('common:content.label.fileName')}</TableCell>
              <TableCell>{t('common:content.label.size')}</TableCell>
              <TableCell>{t('common:content.label.createdAt')}</TableCell>
              <TableCell>{t('common:content.label.action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.state
              .filter(file => !file.markedAsDeleted) // do not render files that are marked as deleted
              .map((file: FileInfo, index: number) => renderFileEntry(file, index))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* openDeleteModal &&
        <DeleteModal
          name={""}
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          onDelete={() => {
            handleDeleteFile(selectedFile!)
            setOpenDeleteModal(false)
            updateFileInTable(selectedFile!)
          }}
        />
       */}
      {openStlViewer &&
        <StlViewerModal
          open={openStlViewer}
          setOpen={setOpenStlViewer}
          url={selectedFile!.document as string}
        />
      }
      {openFileViewer &&
        <FileViewerModal
          open={openFileViewer}
          setOpen={setOpenFileViewer}
          file={selectedFile!}
          onReplaceFile={enableReplacementModal
            ? handleReplaceFile
            : undefined
          }
        />
      }
    </Box>
  )
}