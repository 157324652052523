/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    APIPlanningEnum,
    APIPlanningEnumFromJSON,
    APIPlanningEnumFromJSONTyped,
    APIPlanningEnumToJSON,
} from './APIPlanningEnum';

/**
 * A collection of serializer extensions, which allow for:

* Blacklisting fields through an "exclude" context variable
* Whitelisting fields through an "only" context variable
* Expanding related fields through an "expand" context variable
* Other helper methods

Blacklisting and whitelisting takes precedence over expanding, and can
be used in combination with one another.
 * @export
 * @interface APIRequest
 */
export interface APIRequest {
    /**
     * 
     * @type {string}
     * @memberof APIRequest
     */
    readonly self: string;
    /**
     * 
     * @type {string}
     * @memberof APIRequest
     */
    article: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIRequest
     */
    editors?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof APIRequest
     */
    readonly files: string;
    /**
     * 
     * @type {string}
     * @memberof APIRequest
     */
    readonly read: string;
    /**
     * 
     * @type {string}
     * @memberof APIRequest
     */
    quantities: string;
    /**
     * Calculated costs listed together with quantities as JSON object like {'100': '100', '200': '200', ...}
     * @type {{ [key: string]: any; }}
     * @memberof APIRequest
     */
    calculatedCosts?: { [key: string]: any; };
    /**
     * The costs per quantity reported by suppliers written down as JSON object like {'supplier_1': {quantity_1: price_1, quantity_2: price_2, ...}, ...}
     * @type {{ [key: string]: any; }}
     * @memberof APIRequest
     */
    reportedCosts?: { [key: string]: any; };
    /**
     * 
     * @type {Date}
     * @memberof APIRequest
     */
    wishDate?: Date | null;
    /**
     * 
     * @type {APIPlanningEnum}
     * @memberof APIRequest
     */
    status: APIPlanningEnum;
    /**
     * 
     * @type {boolean}
     * @memberof APIRequest
     */
    priority?: boolean;
    /**
     * 
     * @type {string}
     * @memberof APIRequest
     */
    remarks?: string;
    /**
     * 
     * @type {string}
     * @memberof APIRequest
     */
    certificate?: string;
    /**
     * 
     * @type {string}
     * @memberof APIRequest
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof APIRequest
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof APIRequest
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof APIRequest
     */
    readonly updatedAt: Date;
}

export function APIRequestFromJSON(json: any): APIRequest {
    return APIRequestFromJSONTyped(json, false);
}

export function APIRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'article': json['article'],
        'editors': !exists(json, 'editors') ? undefined : json['editors'],
        'files': json['files'],
        'read': json['read'],
        'quantities': json['quantities'],
        'calculatedCosts': !exists(json, 'calculated_costs') ? undefined : json['calculated_costs'],
        'reportedCosts': !exists(json, 'reported_costs') ? undefined : json['reported_costs'],
        'wishDate': !exists(json, 'wish_date') ? undefined : (json['wish_date'] === null ? null : new Date(json['wish_date'])),
        'status': APIPlanningEnumFromJSON(json['status']),
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
        'certificate': !exists(json, 'certificate') ? undefined : json['certificate'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'createdAt': (new Date(json['created_at'])),
        'updatedAt': (new Date(json['updated_at'])),
    };
}

export function APIRequestToJSON(value?: APIRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'article': value.article,
        'editors': value.editors,
        'quantities': value.quantities,
        'calculated_costs': value.calculatedCosts,
        'reported_costs': value.reportedCosts,
        'wish_date': value.wishDate === undefined ? undefined : (value.wishDate === null ? null : value.wishDate.toISOString().substr(0,10)),
        'status': APIPlanningEnumToJSON(value.status),
        'priority': value.priority,
        'remarks': value.remarks,
        'certificate': value.certificate,
        'country': value.country,
        'description': value.description,
    };
}

