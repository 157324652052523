/* ------------------------ Functional imports start ------------------------ */
import { APIGuestRequest, APIGuestRequestFromJSON } from "../../../generated-types";
import LogTool from "../../../logger/logTools";
import { buildFetchUrl, createFileInfo, getPKfromSelf, handleAPICallV1, parseNestedObjectList } from "../../../utils/functions";
import { FetchKwargs, HTTPMethod } from "../../../utils/types";
import { GuestRequest, GuestRequestFile } from "./types";
/* ------------------------- Functional imports end ------------------------- */

const log = new LogTool({context: 'UnauthRequestFunctions', enable: true, logLevel: 'warn'})

/* -------------------------------------------------------------------------- */
/*                           Utility functions start                          */
/* -------------------------------------------------------------------------- */

export function createGuestRequest(guestRequestResponse: any): GuestRequest {
    const apiGuestRequest: APIGuestRequest = APIGuestRequestFromJSON(guestRequestResponse)
    log.debug("GuestRequestResponse ->", guestRequestResponse)

    return {
        ...apiGuestRequest,
        key: getPKfromSelf(apiGuestRequest.self),
        read: guestRequestResponse.read,
        files: parseNestedObjectList(apiGuestRequest.files, createFileInfo)
    }
    const guestRequest = {
        ...guestRequestResponse,
        key: guestRequestResponse.self,
        createdAt: new Date(guestRequestResponse.created_at).toLocaleString(),
        zipCode: guestRequestResponse.zip_code,
    }
    delete guestRequest.created_at;
    delete guestRequest.zip_code;
    return guestRequest;
}

export function createGuestRequestFile(fileResponse: any): GuestRequestFile {
    const file = {
        ...fileResponse,
        key: fileResponse.self,
        createdAt: new Date(fileResponse.created_at).toLocaleString(),
    }
    delete file.created_at;
    return file;
}

export function inputToAPIGuestRequestJSON(gr: GuestRequest): any {
    const editors = gr?.editors?.map((editor : any) => {
        if(!editor.self && !editor.value && !editor.value.self) return []
        if(!editor.self && editor.value && editor.value.self) return editor.value.self
        return editor.self
    });
    return {
        ...(gr.name && { name: gr.name }),
        ...(gr.email && { email: gr.email }),
        ...(gr.company && { company: gr.company }),
        ...(gr.address && { address: gr.address }),
        ...(gr.zipCode && { zip_code: gr.zipCode }),
        ...(gr.city && { city: gr.city }),
        ...(gr.country && { country: gr.country }),
        ...(gr.phone && { phone: gr.phone }),
        ...(gr.quantity && { quantity: gr.quantity }),
        ...(gr.message && { message: gr.message }),
        ...(gr.status && { status: gr.status }),
        ...(gr.editors && { editors: editors }),
    }
}

/* -------------------------------------------------------------------------- */
/*                             API functions start                            */
/* -------------------------------------------------------------------------- */

export async function fetchGuestRequests(
    {
        onSuccess = (guestRequests : GuestRequest[], count?: number) => null,
        onFinal = () => null,
        onError = (error : any) => null,
        parameter = [],
        url = "guest_request/requests/",
    }: FetchKwargs<GuestRequest>
): Promise<void> {
    log.info("Begin fetching guest requests");
    if(!url.match(/guest_request\/requests\//)) {
        log.error(`The provided URL ${url} does not lead to the correct API endpoint!`);
        return;
    }

    const fetchUrl = buildFetchUrl(url, parameter);
    log.debug("Fetch URL ->", fetchUrl)
    const [response, error] = await handleAPICallV1(HTTPMethod.GET, fetchUrl);

    if(!error && response) {
        log.info("Success fetching guest requests for URL", fetchUrl, ", Response ->", response);
        const guestRequests : GuestRequest[] = response.results.map((guestRequestResponse : any) => createGuestRequest(guestRequestResponse));
        const count = response.count;

        // fetch all pages of a paginated server response
        if(response.next) {
            // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
            fetchGuestRequests({onSuccess: onSuccess, onError: onError, onFinal: onFinal, parameter: parameter, url: response.next})
        }

        // execute callbacks
        onSuccess(guestRequests, count)
        if(!response.next) {
            // the are no more pages -> onSuccess was called for the last time
            onFinal()
        }
    } else {
        log.error("Error fetching guest requests", error);
        onError(error);
    }
}

export async function fetchGuestRequestFiles({
    onSuccess = (files : GuestRequestFile[]) => null,
    onFinal = () => null,
    onError = (error : any) => null,
    parameter = [],
    url = "",
}: FetchKwargs<GuestRequestFile>): Promise<void> {
    log.info("Begin fetching guest request files");

    const fetchUrl = buildFetchUrl(url + "files/", parameter);

    const [response, error] = await handleAPICallV1(HTTPMethod.GET, fetchUrl);

    if(!error && response) {
        log.info("Success fetching guest request files for URL", fetchUrl, ", Response ->", response);
        const files : GuestRequestFile[] = response.results.map((fileResponse : any) => {
            return createGuestRequestFile(fileResponse);
        })

        // fetch all pages of a paginated server response
        if(response.next) {
            // call the async fetch function before calling any of the sync callback functions to ensure the best performance!
            fetchGuestRequestFiles({onSuccess: onSuccess, onError: onError, onFinal: onFinal, parameter: parameter, url: response.next})
        }

        // execute callbacks
        onSuccess(files)
        if(!response.next) {
            // the are no more pages -> onSuccess was called for the last time
            onFinal()
        }
    } else {
        log.error("Error fetching guest request files", error);
        onError(error);
    }
}

export async function fetchGuestRequest(
    url: string,
    onSuccess: (gr: GuestRequest) => void,
    onError: (error : any) => void,
) : Promise<void> {
    log.info("Begin fetching guest request");
    const [response, error] = await handleAPICallV1(HTTPMethod.GET, url + "?expand=editors,files");

    if(!error && response) {
        log.info("Success fetching guest request for URL", url, ", Response ->", response);
        const guestRequest = createGuestRequest(response);
        onSuccess(guestRequest);
    } else {
        log.error("Error fetching guest request", error);
        onError(error);
    }
}

export async function postGuestRequest(
    gr: GuestRequest,
    files: any[],
    onSuccess: () => void,
    onError: (error : any) => void,
) : Promise<void> {
    log.info("Begin posting guest request");
    const json = inputToAPIGuestRequestJSON(gr);
    log.debug("JSON ->", json)
    const [response, error] = await handleAPICallV1(
        HTTPMethod.POST,
        "guest_request/requests/",
        {
            "Content-Type": "application/json",
        },
        json
    );

    if(!error && response) {
        log.info("Success posting guest request, Response ->", response);
        const guestRequest = createGuestRequest(response);
        const success = await postGuestRequestFiles(files, guestRequest);
        if(!success) {
            log.error("Error posting guest request files");
            onError("Error posting guest request files");
            return;
        } else {
            log.info("Success posting guest request files");
            onSuccess();
        }
    } else {
        log.error("Error posting guest request", error);
        onError(error);
    }
}

async function postGuestRequestFiles(files: any[], guestRequest: GuestRequest) : Promise<boolean> {
    log.info("Begin posting guest request files");
    const promises = files.map((file : any) => handleFileUpload(file, guestRequest));
    const results = await Promise.all(promises);
    if(results.includes(false)) {
        log.error("Error posting guest request files");
        return false;
    } else {
        log.info("Success posting guest request files");
        return true;
    }
}

export async function updateGuestRequest(
    gr: GuestRequest,
    onSuccess: (gr: GuestRequest) => void,
    onError: (error : any) => void,
) : Promise<void> {
    log.info("Begin updating guest request");
    const json = inputToAPIGuestRequestJSON(gr);
    log.debug("JSON ->", json, ", Self ->", gr.self)
    const [response, error] = await handleAPICallV1(
        HTTPMethod.PUT,
        gr.self,
        {
            "Content-Type": "application/json",
        },
        json
    );

    if(!error && response) {
        log.info("Success updating guest request, Response ->", response);
        fetchGuestRequest(
            gr.self,
            (gr : GuestRequest) => onSuccess(gr),
            (error : any) => onError(error)
        )
    } else {
        log.error("Error updating guest request", error);
        onError(error);
    }

}

async function handleFileUpload(file: any, guestRequest: GuestRequest) : Promise<boolean> {
    log.info("Begin posting guest request file");
    const formData = new FormData();
    // formData.append('guest_request', guestRequest.self)
    formData.append('document', file)
    log.debug("FormData ->", formData, ", File ->", file)

    // try to upload the file
    const [response, error] = await handleAPICallV1(
        HTTPMethod.POST,
        guestRequest.self + 'files/',
        {
        Accept: '*/*',
        },
        formData,
        "text", // workaround to prevent json parsing on the response
        true
    )

    if(!error && response) {
        log.info("Success posting guest request file, Response ->", response);
        return true;
    } else {
        log.error("Error posting guest request file", error);
        return false;
    }
    /* return false; */
}

export async function deleteGuestRequests(
    guestRequests: GuestRequest[],
    onSuccess: () => void,
    onError: (error : any) => void,
) : Promise<void> {
    log.info("Begin deleting guest requests");
    const promises = guestRequests.map((guestRequest : GuestRequest) => deleteGuestRequest(guestRequest));
    const results = await Promise.all(promises);
    if(results.includes(false)) {
        log.error("Error deleting guest requests");
        onError("Error deleting guest requests");
        return;
    } else {
        log.info("Success deleting guest requests");
        onSuccess();
    }
}

async function deleteGuestRequest(guestRequest: GuestRequest) : Promise<boolean> {
    log.info("Begin deleting guest request");
    const [response, error] = await handleAPICallV1(
        HTTPMethod.DELETE,
        guestRequest.self,
        undefined,
        undefined,
        "text"
    );

    if(!error) {
        log.info("Success deleting guest request, Response ->", response);
        return true;
    } else {
        log.error("Error deleting guest request", error);
        return false;
    }
}
