import { FormFieldType } from "../../Form/utils/types";
import { EditorFieldObject } from "./types";

/**
 * Generates a new Text-EditorFieldObject.
 * @param key React list-render-key
 * @param label Label of the form field
 * @returns
 */
export function generateTextField(
  key: string | number,
  label: string,
  required: boolean = false
): EditorFieldObject {
  return {
    type: 'text',
    key: key,
    label: label,
    required: required,
    value: null,
    onChange: () => null,
  }
}

/**
 * Generates a new form field with a 'Add new form field' button.
 * @returns
 */
export function generateAddFormFieldButton(): EditorFieldObject {
  return {type: 'addFormField'}
}

/**
 * Takes a list of EditorFieldObjects and cleans it up such that it can be rendered
 * by our Form component.
 * @param json The list of EditorFieldObjects to clean up.
 */
export function cleanUpFormFields(formFields: EditorFieldObject[]): FormFieldType[] {
  const cleanedUpFormFields = formFields.filter(
    // remove the 'addFormField' entry
    (field: EditorFieldObject) => field.type !== 'addFormField'
  ).map(
    // provide meaningful keys and convert EditorFieldObjects to FormFieldType
    (fieldObj: EditorFieldObject) => {
      if(fieldObj.type !== 'addFormField') {
        return {
          ...fieldObj,
          // remove all space-like characters and convert the label to all lower case
          key: fieldObj.label.replace(/\s/g, '_').replace(/\./g, '').toLowerCase(),
          // include the required field
          // required: fieldObj.required ?? false,
          // specify render size
          xl: 12,
        } as FormFieldType
      }
    }
  )
  return cleanedUpFormFields as FormFieldType[]
}