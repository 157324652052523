/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A collection of serializer extensions, which allow for:

* Blacklisting fields through an "exclude" context variable
* Whitelisting fields through an "only" context variable
* Expanding related fields through an "expand" context variable
* Other helper methods

Blacklisting and whitelisting takes precedence over expanding, and can
be used in combination with one another.
 * @export
 * @interface APISharepoint
 */
export interface APISharepoint {
    /**
     * 
     * @type {string}
     * @memberof APISharepoint
     */
    readonly self: string;
    /**
     * 
     * @type {boolean}
     * @memberof APISharepoint
     */
    readonly isBomShare: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharepoint
     */
    readonly isGuestRequestShare: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof APISharepoint
     */
    readonly isRequestShare: boolean;
    /**
     * 
     * @type {Date}
     * @memberof APISharepoint
     */
    readonly createdAt: Date;
    /**
     * Time in days that the sharepoint should be active. This field is used as the single source of truth for the time_to_live of guest users. A share_duration of 0 (days) represents a time-unlimited sharepoint.
     * @type {number}
     * @memberof APISharepoint
     */
    shareDuration?: number;
    /**
     * 
     * @type {string}
     * @memberof APISharepoint
     */
    readonly unreadFeedbackSubmissionCount: string;
    /**
     * 
     * @type {string}
     * @memberof APISharepoint
     */
    readonly feedbackForm: string;
    /**
     * 
     * @type {string}
     * @memberof APISharepoint
     */
    readonly createdBy: string;
    /**
     * 
     * @type {string}
     * @memberof APISharepoint
     */
    readonly sharedWith: string;
    /**
     * 
     * @type {string}
     * @memberof APISharepoint
     */
    readonly sharedArticles: string;
    /**
     * 
     * @type {string}
     * @memberof APISharepoint
     */
    readonly sharedBOMs: string;
    /**
     * 
     * @type {string}
     * @memberof APISharepoint
     */
    readonly sharedGuestRequest: string;
    /**
     * 
     * @type {string}
     * @memberof APISharepoint
     */
    readonly sharedRequest: string;
    /**
     * 
     * @type {string}
     * @memberof APISharepoint
     */
    readonly feedbackSubmissions: string;
}

export function APISharepointFromJSON(json: any): APISharepoint {
    return APISharepointFromJSONTyped(json, false);
}

export function APISharepointFromJSONTyped(json: any, ignoreDiscriminator: boolean): APISharepoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'isBomShare': json['is_bom_share'],
        'isGuestRequestShare': json['is_guest_request_share'],
        'isRequestShare': json['is_request_share'],
        'createdAt': (new Date(json['created_at'])),
        'shareDuration': !exists(json, 'share_duration') ? undefined : json['share_duration'],
        'unreadFeedbackSubmissionCount': json['unread_feedback_submission_count'],
        'feedbackForm': json['feedback_form'],
        'createdBy': json['created_by'],
        'sharedWith': json['shared_with'],
        'sharedArticles': json['shared_articles'],
        'sharedBOMs': json['shared_BOMs'],
        'sharedGuestRequest': json['shared_guest_request'],
        'sharedRequest': json['shared_request'],
        'feedbackSubmissions': json['feedback_submissions'],
    };
}

export function APISharepointToJSON(value?: APISharepoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'share_duration': value.shareDuration,
    };
}

