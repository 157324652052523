/* -------------------------- Design imports start -------------------------- */
import { toast } from 'react-toastify';
import { Box, Card, CircularProgress, IconButton, Modal, Skeleton, Slider, Stack, Typography } from '@mui/material';
import { ChevronLeftRounded, ChevronRightRounded, CloseRounded, ZoomInRounded, ZoomOutRounded } from '@mui/icons-material';
/* --------------------------- Design imports end --------------------------- */



/* ------------------------ Functional imports start ------------------------ */
import LogTool from '../../logger/logTools'
import { useRef, useState } from 'react'
import { usePdf } from '@mikecousins/react-pdf';
import { FileInfo } from '../../utils/types';
/* ------------------------- Functional import end -------------------------- */


interface Props {
  file: FileInfo
}



/* -------------------------------------------------------------------------- */
/*                               Start component                              */
/* -------------------------------------------------------------------------- */

export default function PdfViewer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {file} = props
  const log = new LogTool({context: 'PdfViewer', enable: false, logLevel: 'debug'})


  const minScale = 0.1
  const maxScale = 2
  /* --------------------------- Non state data end --------------------------- */




  /* ---------------------------- Flag states start --------------------------- */
  // this allows us to implement drag-scrolling with the mouse
  const [mouseDown, setMouseDown] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */




  /* --------------------------- Data states start ---------------------------- */
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [scale, setScale] = useState<number>(1)

  const canvasScrollContainerRef = useRef<HTMLElement | null>(null)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  const {pdfDocument, pdfPage} = usePdf({
    file: file.document!,
    page: currentPage,
    canvasRef: canvasRef,
    scale
  })
  /* ---------------------------- Data states end ----------------------------- */




  /* ------------------------------ Effects start ----------------------------- */

  /* ------------------------------- Effects end ------------------------------ */




  /* ------------------------- Utility functions start ------------------------ */
  /**
   * Takes a value within a 'from-range' and linearly maps it into a 'to-range'. That
   * mapped value is then returned.
   * @param num
   * @param fromRangeMin
   * @param fromRangeMax
   * @param toRangeMin
   * @param toRangeMax
   * @returns
   */
  function mapBetweenRanges(num: number, fromRangeMin: number, fromRangeMax: number, toRangeMin: number, toRangeMax: number) {
    return ((num - fromRangeMin) * (toRangeMax - toRangeMin)) / (fromRangeMax - fromRangeMin) + toRangeMin
  }
  /* -------------------------- Utility functions end ------------------------- */




  /* ------------------------ Callback functions start ------------------------ */
  const handleMouseDrag = (event: any) => {
    if(!mouseDown) return

    if(canvasScrollContainerRef.current) {
      canvasScrollContainerRef.current.scrollLeft -= event.movementX
      canvasScrollContainerRef.current.scrollTop -= event.movementY
      log.debug(
        'mouseMovementX ->', event.movementX,
        'mouseMovementY ->', event.movementY,
        'canvas.scrollLeft ->', canvasScrollContainerRef.current?.scrollLeft,
        'canvas.scrollTop ->', canvasScrollContainerRef.current?.scrollTop,
      )
    }

  }



  const handleZoomOut = () => {
    setScale(prev => {
      if(prev <= minScale+0.25) return minScale
      else return prev-=0.25
    })
  }

  const handleZoomIn = () => {
    setScale(prev => {
      if(prev >= maxScale-0.25) return maxScale
      else return prev+=0.25
    })
  }

  const handleZoomSliderChange = (_: any, value: number | number[]) => {
    if(Array.isArray(value)) return
    setScale(mapBetweenRanges(value, 0, 100, minScale, maxScale))
  }
  /* ------------------------- Callback functions end ------------------------- */




  /* ------------------------- Render constants start ------------------------- */

  /* -------------------------- Render constants end -------------------------- */




  /* ------------------------ Pre render actions start ------------------------ */
  // if(canvasRef.current) {
  //   if(mouseDown) canvasRef.current.style.cursor = 'grabbing'
  //   else canvasRef.current.style.cursor = 'grab'
  // }
  /* ------------------------- Pre render actions end ------------------------- */


  log.debug(
    'pdfDocument ->', pdfDocument,
    'pdfPage ->', pdfPage,
    'mouseDown ->', mouseDown,
  )


  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        cursor: mouseDown ? 'grabbing' : 'auto'
      }}
      onMouseMove={handleMouseDrag}
      onMouseUp={() => setMouseDown(false)}
      onMouseLeave={(e: any) => {
        setMouseDown(false)
      }} // disable mouse drag when the cursor leaves the container
    >
      <Box
        id='pdfCanvasContainer'
        // padding='16px'
        height='calc(100% - (2rem + 0.5rem + 40px))' // height of the container minus the height of the pdf controls
        width='100%'
      >
        <Box
          id='scrollContainer'
          ref={canvasScrollContainerRef}
          height='100%'
          width='100%'
          overflow='auto'
        >
          {!pdfDocument && <Skeleton variant='rectangular' animation='wave' height='700px' />}
          {pdfDocument &&
            <canvas
              id='pdfCanvas'
              ref={canvasRef}
              onMouseDown={() => setMouseDown(true)} // enable dragging the pdf with the mouse
              // TODO: enable zooming via strg + scrolling
              // onWheel={}
              style={{
                display: 'block',
                padding: '4px',
                marginLeft: 'auto',
                marginRight: 'auto',
                border: '1px solid lightGray',
                boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.15)',
                cursor: mouseDown ? 'grabbing' : 'grab',
              }}
            ></canvas>
          }
        </Box>
      </Box>
      <Card
        id='pdfControlsContainer'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: '16px',
          p: '4px',
          border: '1px solid lightGray'
        }}
      >
        <Stack
          id='zoomControlsContainer'
          direction='row'
          alignItems='center'
          width={200}
        >
          <IconButton
            onClick={handleZoomOut}
            disabled={scale <= minScale}
          >
            <ZoomOutRounded fontSize='large'/>
          </IconButton>
          <Slider
            value={Math.ceil(mapBetweenRanges(scale, minScale, maxScale, 0, 100))}
            onChange={handleZoomSliderChange}
          />
          <IconButton
            onClick={handleZoomIn}
            disabled={scale >= maxScale}
          >
            <ZoomInRounded fontSize='large'/>
          </IconButton>
        </Stack>
        <Box id='pageNavigationContainer' display='flex' alignItems='center'>
          <IconButton
            onClick={() => setCurrentPage(prev => prev > 1 ? prev-=1 : prev)}
            disabled={!pdfDocument || currentPage <= 1}
          >
            <ChevronLeftRounded/>
          </IconButton>
          <Typography variant='body1' pr='2px'>{currentPage}</Typography>
          <Typography variant='body1' pl='2px' pr='4px'>/</Typography>
          {pdfDocument
            ? (
              <Typography variant='body1'>
                {pdfDocument!.numPages}
              </Typography>
            )
            : <CircularProgress color='primary' size='1rem' />
          }
          <IconButton
            onClick={() => setCurrentPage(prev => prev < pdfDocument!.numPages ? prev+=1 : prev)}
            disabled={!pdfDocument || currentPage >= pdfDocument.numPages}
          >
            <ChevronRightRounded/>
          </IconButton>
        </Box>
      </Card>
    </div>
  )
}
