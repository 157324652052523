/* tslint:disable */
/* eslint-disable */
/**
 * Assemblean Production Solutions
 * Produce the lean way with APCS
 *
 * The version of the OpenAPI document: 0.9.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * A collection of serializer extensions, which allow for:

* Blacklisting fields through an "exclude" context variable
* Whitelisting fields through an "only" context variable
* Expanding related fields through an "expand" context variable
* Other helper methods

Blacklisting and whitelisting takes precedence over expanding, and can
be used in combination with one another.
 * @export
 * @interface APIGuestRequest
 */
export interface APIGuestRequest {
    /**
     * 
     * @type {string}
     * @memberof APIGuestRequest
     */
    readonly self: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof APIGuestRequest
     */
    editors?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof APIGuestRequest
     */
    readonly files: string;
    /**
     * 
     * @type {string}
     * @memberof APIGuestRequest
     */
    readonly read: string;
    /**
     * 
     * @type {string}
     * @memberof APIGuestRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof APIGuestRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof APIGuestRequest
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof APIGuestRequest
     */
    company: string;
    /**
     * 
     * @type {string}
     * @memberof APIGuestRequest
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGuestRequest
     */
    zipCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGuestRequest
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGuestRequest
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGuestRequest
     */
    quantity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof APIGuestRequest
     */
    message?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof APIGuestRequest
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof APIGuestRequest
     */
    status?: string | null;
}

export function APIGuestRequestFromJSON(json: any): APIGuestRequest {
    return APIGuestRequestFromJSONTyped(json, false);
}

export function APIGuestRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): APIGuestRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': json['self'],
        'editors': !exists(json, 'editors') ? undefined : json['editors'],
        'files': json['files'],
        'read': json['read'],
        'name': json['name'],
        'email': json['email'],
        'phone': json['phone'],
        'company': json['company'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'zipCode': !exists(json, 'zip_code') ? undefined : json['zip_code'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'createdAt': (new Date(json['created_at'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function APIGuestRequestToJSON(value?: APIGuestRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'editors': value.editors,
        'name': value.name,
        'email': value.email,
        'phone': value.phone,
        'company': value.company,
        'address': value.address,
        'zip_code': value.zipCode,
        'city': value.city,
        'country': value.country,
        'quantity': value.quantity,
        'message': value.message,
        'status': value.status,
    };
}

