/* -------------------------- Design imports start -------------------------- */
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useRef, useState } from 'react'
import LogTool from '../../../logger/logTools'
import { useTranslation } from 'react-i18next'
import { useCountContext, useUserContext } from '../../../utils/context'
import FilterComponent, { FilterOptions } from '../../../components/widgets/FilterMenu'
import Table, { ColumnsType } from 'antd/es/table'
import GetConstant from '../utils/constants'
import {
  deleteOffers,
  fetchOffer,
  fetchOffers,
  inputToAPIOfferJSON,
  updateOffer,
} from '../utils/functions'
import { Chip, CircularProgress, Grid } from '@mui/material'
import LoadingSkeleton from '../../../components/widgets/LoadingSkeleton'
import { Space } from 'antd'
import { DeleteOutlined, EditOutlined, FilterAltOutlined } from '@mui/icons-material'
import ColumnSelector from '../../../components/widgets/ColumnSelector'
import { canDelete, handleAPICallV1, isPrivileged } from '../../../utils/functions'
import DeleteModal from '../../../components/widgets/DeleteModal'
import { toast } from 'react-toastify'
import FilesDrawer from '../../../components/FilesDrawer'
import OfferDrawer from './CreateOfferDrawer'
import OfferStaffDrawer from './OfferStaffDrawer'
import { Offer } from '../utils/types'
import ContractDrawer from '../../Contract/Contract/ContractDrawer'
import Button from '../../../components/Button'
import { inputToAPIContractJSON } from '../../Contract/utils/functions'
import { FileInfo, HTTPMethod } from '../../../utils/types'
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  openInfoPanel: boolean
  setOpenInfoPanel: React.Dispatch<React.SetStateAction<boolean>>
  selectedOffer: Offer | undefined
  setSelectedOffer: React.Dispatch<React.SetStateAction<Offer | undefined>>
  openFilesDrawer: boolean
  setOpenFilesDrawer: React.Dispatch<React.SetStateAction<boolean>>
}

/* -------------------------------------------------------------------------- */
/*                                Start Component                             */
/* -------------------------------------------------------------------------- */
export default function OfferOverviewPage(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const log = new LogTool({ context: 'OfferOverviewPage', enable: true, logLevel: 'warn' })
  const { t } = useTranslation(['request', 'common'])
  const {
    openInfoPanel,
    setOpenInfoPanel,
    selectedOffer,
    setSelectedOffer,
    openFilesDrawer,
    setOpenFilesDrawer,
  } = props
  const { user, userPermissions } = useUserContext()
  const columnsData: ColumnsType = GetConstant({ name: 'offerTableColumns' }) as ColumnsType
  const { initialOffers, setTabValue } = useCountContext()
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [loading, setLoading] = useState<boolean>(false)
  const [offerDrawerOpen, setOfferDrawerOpen] = useState<boolean>(false)
  const [offerStaffDrawerOpen, setOfferStaffDrawerOpen] = useState<boolean>(false)
  const [contractDrawerOpen, setContractDrawerOpen] = useState<boolean>(false)
  const [filterVisible, setFilterVisible] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  const dataSource = useRef<Offer[]>([])
  const [myFilterOptions, setMyFilterOptions] = useState<FilterOptions>({})
  const [filteredData, setFilteredData] = useState<Offer[]>([])
  const [defaultColumns] = useState<string[]>([
    "read",
    'self',
    'request',
    'article',
    'prices',
    'status',
    'editors',
    'action',
  ])
  const [columns] = useState<ColumnsType>(columnsData)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [selectedOffers, setSelectedOffers] = useState<Offer[]>([])
  const [totalOffers, setTotalOffers] = useState<number>(0)
  const [offerFormInput, setOfferFormInput] = useState<any>({})
  const [offerStaffFormInput, setOfferStaffFormInput] = useState<any>({})
  const [contractFormInput, setContractFormInput] = useState<any>({})
  const [newOffers, setNewOffers] = useState<any[]>(initialOffers)
  /* ----------------------------- Data states end ---------------------------- */
  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    setLoading(true)
    fetchOffers({
      onSuccess: (offers: Offer[], count: any) => {
        dataSource.current = [...dataSource.current, ...offers]
        setTotalOffers(count)
        setFilteredData(dataSource.current)
        setLoading(false)
      },
      onError: (error: any) => {
        log.error('Error fetching Offers -> ', error)
        setLoading(false)
      },
      parameter: ['expand=editors,request,files'],
    })
  }, [])
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  const filterData = (data: any[], filterOptions: { [x: string]: any }) => {
    if (!data || !Array.isArray(data)) {
      return [] // Rückgabe eines leeren Arrays, wenn die Daten ungültig sind
    }

    return data.filter(d => {
      return Object.keys(filterOptions).every(key => {
        const filterValues = filterOptions[key]
        if (!filterValues.length) return true // Kein Filter angewendet
        if (key === 'name') {
          return filterValues.some((value: string) =>
            d.name.toLowerCase().includes(value.toLowerCase())
          )
        } else if (key === 'quantity') {
          return filterValues.some((value: string) => parseInt(d.in_storage) >= parseInt(value))
        } else {
          const cellValue = d[key]
          return filterValues.some((value: string) =>
            cellValue?.toString().toLowerCase().includes(value.toLowerCase())
          )
        }
      })
    })
  }
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */

  /**
   * Refreshes the data source by fetching the offers from the API
   */
  const refreshDataSource = (): void => {
    dataSource.current = []
    fetchOffers({
      onSuccess: (offers: Offer[], count: any) => {
        dataSource.current = [...dataSource.current, ...offers]
        setTotalOffers(count)
        setFilteredData(dataSource.current)
      },
      onError: (error: any) => {
        log.error('Error fetching Offers -> ', error)
      },
      parameter: ['expand=editors,request'],
    })
  }

  useEffect(() => {
    // Funktion zum Aktualisieren der Daten, wenn der TabValue auf die BillOverviewPage gesetzt wird
    const handleTabChange: (newValue: number) => number = (newValue: number) => {
      if (newValue === 4) {
        // Angenommen, der Index für die BillOverviewPage ist 4
        refreshDataSource()
      }
      return newValue
    }

    // Registrieren Sie die handleTabChange-Funktion als Callback für die setTabValue-Prop
    if (setTabValue) {
      setTabValue(handleTabChange as any)
    }
  }, [setTabValue, refreshDataSource])

  async function handleUpdateOffer(input: any) {
    log.debug('Updating offer -> ', input)
    const json = inputToAPIOfferJSON(input)
    const [response, error] = await handleAPICallV1(
      HTTPMethod.PATCH,
      input.self,
      {
        'Content-Type': 'application/json',
      },
      json
    )
    if (!error && response) {
      log.info('Success updating request', response)
      fetchOffer(
        response.self,
        (offer: Offer) => {
          dataSource.current = dataSource.current.map(o => (o.key === offer.key ? offer : o))
          setFilteredData(dataSource.current)
          toast.success(t('request:feedback.success.updateRequest'))
        },
        (error: any) => {
          log.error('Error fetching updated offer -> ', error)
          toast.error(t('request:feedback.error.updateRequest'))
        },
        ['expand=editors,request']
      )
      setSelectedRowKeys([])
      setSelectedOffers([])
      setSelectedOffer(undefined)
      setOpenInfoPanel(false)
      return response.files
    } else {
      log.error('Error updating request', error)
      toast.error(t('request:feedback.error.updateRequest'))
      setSelectedRowKeys([])
      setSelectedOffers([])
      setSelectedOffer(undefined)
      setOpenInfoPanel(false)
    }
  }

  const handleCreateContract = async (contractInput: any) => {
    const json = inputToAPIContractJSON({ ...contractInput, status: 0 })
    log.debug('Creating contract -> ', json)
    const [response, error] = await handleAPICallV1(
      HTTPMethod.POST,
      'contract/contracts/',
      {
        'Content-Type': 'application/json',
      },
      json
    )
    if (!error && response) {
      log.info('Success creating contract', response)
      toast.success(t('contract:feedback.success.contractCreated'))
      refreshDataSource()
      log.debug('contract created -> ', response)
      return response.files
    } else {
      log.error('Error creating contract', error)
      toast.error(t('contract:feedback.error.contractNotCreated'))
    }
  }

  const handleContract = (event: any, selectedOffer: Offer) => {
    log.debug('Creating Contract for offer -> ', selectedOffer)
    event.stopPropagation()
    event.preventDefault()
    const newContract: any = {
      offer: selectedOffer,
    }
    const selectedRowKey = selectedOffer.key
    setSelectedRowKeys([selectedRowKey])
    setContractFormInput(newContract)
    setContractDrawerOpen(true)
    refreshDataSource()
  }

  const handleDeclineOffer = (event: any, selectedOffer: Offer) => {
    log.debug('Declining offer -> ', selectedOffer)
    event.stopPropagation()
    event.preventDefault()
    updateOffer(
      { ...selectedOffer, status: 2 },
      [],
      (offer: Offer) => {
        log.debug('Offer declined -> ', offer)
        dataSource.current = dataSource.current.map(o => (o.key === offer.key ? offer : o))
        setFilteredData(dataSource.current)
        refreshDataSource()
        toast.success(t('request:feedback.success.declineOffer'))
      },
      (error: any) => {
        log.error('Error declining offer -> ', error)
        toast.error(t('request:feedback.error.declineOffer'))
      }
    )
  }

  const handleApplyFilter = (filterOptions: Record<string, any>) => {
    setMyFilterOptions(filterOptions)

    const filteredProducts = dataSource.current.filter(d => {
      return Object.keys(filterOptions).every(key => {
        const filterValues = filterOptions[key]
        if (!filterValues.length) return true // no filter applied

        const cellValue = d[key as keyof Offer]
        if (cellValue === null) return null // Ignore null values
        if (typeof cellValue === 'string') {
          // String-based filtering
          if (typeof cellValue === 'string') {
            return filterValues.some((value: string) =>
              cellValue.toLowerCase().includes(value.toLowerCase())
            )
          }
        } else if (typeof cellValue === 'number') {
          // Number-based filtering
          const cellValueAsNumber =
            typeof cellValue === 'string' ? parseFloat(cellValue) : cellValue
          return filterValues.some((value: number) => cellValueAsNumber == value)
        }

        return true // Ignore other data types or no match
      })
    })

    setFilteredData(filteredProducts)
    setFilterVisible(false)
  }

  const handleColumnChange = (checkedColumns: string[]) => {
    setVisibleColumns(checkedColumns)
  }

  const handleCancelFilter = () => {
    setFilterVisible(false)
  }

  const handleReset = () => {
    setMyFilterOptions({})
    setFilteredData(dataSource.current)
  }

  const handleShowFilter = () => {
    setFilterVisible(true)
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const offerTableColumns: any = GetConstant({
    name: 'offerTableColumns',
    newOffers: newOffers,
    handleContract: handleContract,
    handleDecline: handleDeclineOffer,
  })
  const [visibleColumns, setVisibleColumns] = useState<string[]>(defaultColumns)
  const filteredColumns = offerTableColumns.filter((column: any) => {
    return visibleColumns.includes(column.key?.toString() ?? '')
  })
  const chips = Object.entries(myFilterOptions).map(([key, values]) => {
    if (!values.length) return <></>
    return (
      <Chip
        key={key}
        label={`${t('common:content.label.' + key)}: ${values}`}
        onDelete={() => {
          setMyFilterOptions(prev => {
            const updatedOptions = { ...prev, [key]: [] }
            setFilteredData(filterData(dataSource.current, updatedOptions))
            return updatedOptions
          })
        }}
      />
    )
  })

  const buttonContainerStyle = {
    marginLeft: openInfoPanel ? '-270px' : '0',
    transition: 'margin-left 0.3s ease-in-out',
    marginBottom: '5px',
  }

  if (loading) {
    return <LoadingSkeleton pagetitle="" />
  }
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  let offerFiles: FileInfo[] = []
  if(selectedOffer) {
    if(typeof selectedOffer.files === 'string'
      || typeof selectedOffer.files === 'undefined')
    {
      throw new Error(
        `Offer ${selectedOffer.key} does not provide its files. Offer files are `
        + 'required. Make sure to expand the "files" property when fetching '
        + 'the offers.'
      )
    }

    offerFiles = selectedOffer.files
  }
  /* ------------------------- Pre render actions end ------------------------- */

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>{chips}</Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="flex-end" style={buttonContainerStyle}>
          {isPrivileged(user, 'STAFF') && (
            <Grid item>
              <Space>
                <Button
                  id="editOfferButton"
                  size="small"
                  variant="outlined"
                  color="primary"
                  startIcon={<EditOutlined />}
                  onClick={() => {
                    if (isPrivileged(user, 'STAFF')) {
                      setOfferStaffFormInput(selectedOffers[0])
                      setOfferStaffDrawerOpen(true)
                    } else {
                      setOfferFormInput(selectedOffers[0])
                      setOfferDrawerOpen(true)
                    }
                  }}
                  disabled={selectedRowKeys.length !== 1}
                >
                  {t('request:interaction.button.editOffer')}
                </Button>
              </Space>
            </Grid>
          )}
          {(canDelete(userPermissions, 'request:offer') || isPrivileged(user, 'STAFF')) && (
            <Grid item>
              <Space>
                <Button
                  id="deleteOfferButton"
                  size="small"
                  variant="outlined"
                  color="primary"
                  startIcon={<DeleteOutlined />}
                  onClick={() => setOpenDelete(true)}
                  disabled={
                    selectedRowKeys.length === 0 || selectedOffers.some(offer => offer.status !== 2)
                  }
                >
                  {t('request:interaction.button.deleteOffers')}
                </Button>
              </Space>
            </Grid>
          )}
          <Grid item>
            <Space>
              <Button
                id="filterButton"
                size="small"
                variant="outlined"
                color="primary"
                startIcon={<FilterAltOutlined />}
                onClick={handleShowFilter}
              >
                {t('common:content.label.filter')}
              </Button>
            </Space>
          </Grid>
          <Grid item>
            <Space>
              <ColumnSelector
                columns={columns as Array<{ key: string; title: string }>}
                onChangeColumns={handleColumnChange}
                defaultColumns={defaultColumns}
              />
            </Space>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table
          id="offerTable"
          columns={filteredColumns}
          dataSource={filteredData}
          pagination={{
            total: totalOffers,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
          }}
          locale={{
            emptyText:
              filteredData.length === 0 ? t('common:content.label.noData') : <CircularProgress />,
          }}
          rowSelection={{
            type: 'checkbox', // Set to 'checkbox' for multiple row selection
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedKeys: React.Key[], selectedRows: any[]) => {
              setSelectedRowKeys(selectedKeys)
              setSelectedOffers(selectedRows)
            },
          }}
          onRow={(record: any, rowIndex) => {
            return {
              onClick: event => {
                setSelectedOffer(record as any)
                const newOffer = newOffers.find(offer => offer.key === record.key)
                if(newOffer && !newOffer.read) {
                  setNewOffers(prev => prev.map(offer => {
                    if(offer.key === record.key) {
                      return {...offer, read: true}
                    }
                    return offer
                  }))
                }
                if (selectedRowKeys.includes(record.key as React.Key)) {
                  setSelectedRowKeys(selectedRowKeys.filter(key => key !== record.key))
                  setSelectedOffers(selectedOffers.filter(offer => offer.key !== record.key))
                  setSelectedOffer(undefined)
                  setOpenInfoPanel(false)
                } else {
                  setSelectedRowKeys([...selectedRowKeys, record.key as React.Key])
                  setSelectedOffers([...selectedOffers, record as any])
                  setSelectedOffer(record as any)
                  setOpenInfoPanel(true)
                }
                /* fetchOfferFiles(
                    {
                        onSuccess: (data) => {
                            console.log(data)
                        },
                        onError: (error) => {
                            console.log(error)
                        },
                        url: record.self
                    }
                ) */
              },
            }
          }}
        />
        {filterVisible && (
          <FilterComponent
            columns={offerTableColumns}
            onApplyFilter={handleApplyFilter}
            onCancelFilter={handleCancelFilter}
            onResetFilter={handleReset}
            filterOptions={myFilterOptions}
          />
        )}
        <OfferDrawer
          open={offerDrawerOpen}
          setOpen={setOfferDrawerOpen}
          formState={{
            state: offerFormInput,
            setState: setOfferFormInput,
          }}
          /* onCreateConfirm={handleCreateOffer} */
          enableEditMode={true}
          setTabValue={setTabValue}
        />
        <ContractDrawer
          open={contractDrawerOpen}
          setOpen={setContractDrawerOpen}
          formState={{
            state: contractFormInput,
            setState: setContractFormInput,
          }}
          enableEditMode={false}
          onCreateConfirm={handleCreateContract}
          setTabValue={setTabValue}
        />
        {isPrivileged(user, 'STAFF') && (
          <OfferStaffDrawer
            open={offerStaffDrawerOpen}
            setOpen={setOfferStaffDrawerOpen}
            formState={{
              state: offerStaffFormInput,
              setState: setOfferStaffFormInput,
            }}
            onEditConfirm={handleUpdateOffer}
          />
        )}
      </Grid>
      <DeleteModal
        open={openDelete}
        setOpen={setOpenDelete}
        onDelete={() => {
          deleteOffers(
            selectedOffers,
            () => {
              toast.success(t('request:feedback.success.deleteOffers'))
              setSelectedRowKeys([])
              setSelectedOffers([])
              setFilteredData(prev => prev.filter(d => !selectedOffers.includes(d)))
              setOpenDelete(false)
            },
            (error: any) => {
              log.error(error)
              toast.error(error)
              setOpenDelete(false)
            }
          )
        }}
        name={t('common:content.label.offers')}
      />
      <FilesDrawer
        open={openFilesDrawer}
        setOpen={setOpenFilesDrawer}
        onClose={() => {}}
        files={offerFiles}
        title={t('common:content.label.offerFiles') as string}
      />
    </Grid>
  )
}
