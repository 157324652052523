/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LogTool from '../logger/logTools'
import { createFileInfo, formatBytes, handleAPICallV1, isPrivileged } from '../utils/functions'
import { FileCollection, FileInfo, HTTPMethod } from '../utils/types'
import moment from 'moment'
import { useUserContext } from '../utils/context'
import { forEach } from 'cypress/types/lodash'
/* ------------------------- Functional imports end ------------------------- */



/* -------------------------- Design imports start -------------------------- */
import { toast } from 'react-toastify'
import MultilevelDrawer from './layout/MultilevelDrawer'
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import { Delete, Download, FileOpenRounded, ViewInAr } from '@mui/icons-material'
import FilesTable from './widgets/FilesTable'
import DeleteModal from './widgets/DeleteModal'
import StlViewerModal from '../features/StlViewer/StlViewerModal'
import PdfViewerModal from '../features/PdfHandling/PdfModal'
import Button from './Button'
/* --------------------------- Design imports end --------------------------- */

type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  onClose?: () => void
  onConfirm?: () => void
  // the FilesDrawer can be used with a simple list of files or with multiple file collections
  files: FileInfo[] | FileCollection[]
  title?: string
  size?: 'big' | 'small'
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export default function FilesDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    open,
    setOpen,
    onClose = () => null,
    onConfirm = () => null,
    files,
    title,
    size = 'big',
  } = props
  const { t } = useTranslation()
  const log = new LogTool({ context: 'FilesDrawer', enable: true, logLevel: 'debug' })
  const { user } = useUserContext()

  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  const [loading, setLoading] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openStlViewer, setOpenStlViewer] = useState(false)
  const [openPdfViewer, setOpenPdfViewer] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  // we need to decouple the rendered files state from the files that were
  // provided as input. This allows us to implement the 'markAsDeleted'-feature:
  // files will only be deleted when the user confirms all actions, until then
  // they will only be 'markedAsDeleted'
  const [fileCollections, setFileCollections] = useState<{title: string, files: FileInfo[]}[] | null>(null)
  const [fileList, setFileList] = useState<FileInfo[] | null>(null)
  const [deleteFileName, setDeleteFileName] = useState<string>('')
  const [deleteFile, setDeleteFile] = useState<any | undefined>()
  const [selectedFile, setSelectedFile] = useState<FileInfo | null>(null)
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    if(!Array.isArray(files)) return
    // whenever the files input changes, we should update the render state: fileCollections or fileList
    // This is done in an effect rather in the pre-render section, as an effect makes it easier
    // to react on changes of the files input.
    if(fileList === null && fileCollections === null) {
      // the render state has not been initialized yet. Do nothing, initializing
      // is done in the pre-render section
    } else {
      if(fileList !== null) setFileList(files as FileInfo[])
      if(fileCollections !== null) setFileCollections(files as FileCollection[])
    }
  }, [files])
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */

  const createFile = (fileInput: any) => {
    const file = {
      ...fileInput,
      key: fileInput.self,
      createdAt: new Date(fileInput.created_at).toLocaleString(),
    }
    delete file.created_at;
    return file
  }

  function disableConfirmButton(): boolean {
    // collect all files
    let allFiles: FileInfo[] = []
    if(fileCollections !== null) {
      fileCollections.forEach(collection => {
        allFiles = [...allFiles, ...collection.files]
      })
    } else if(fileList !== null) {
      allFiles = [...fileList]
    }

    if(allFiles.every(file => !file.markedAsDeleted)) {
      // no files were marked as deleted -> no need to save changes
      return true
    } else {
      // at least one file was marked as deleted -> enable 'save changes button'
      return false
    }
  }

  // async function fetchFileList(fileListUrl: string) {
  //   log.info('Begin fetching file list for', fileListUrl)
  //   const [response, error] = await handleAPICallV1(
  //     HTTPMethod.GET,
  //     fileListUrl
  //   )

  //   if(!error && response) {
  //     log.info('Success fetching file list for', fileListUrl)
  //     const files = response.results.map((file: any) => createFileInfo(file))

  //     if(response.next) {
  //       fetchFileList(response.next)
  //     }

  //     setFileList(prev => {
  //       // add fetched files to list or start a new list
  //       if(Array.isArray(prev)) return [...prev, ...files]
  //       else return files
  //     })
  //   } else {
  //     log.info('Error fetching file list', error)
  //     toast.error(t('common:feedback.error.fetchFailed') || '')
  //   }
  // }

  // async function fetchSingleFile(fileUrl: string) {
  //   log.info('Begin fetching file for', fileUrl)
  //   const [response, error] = await handleAPICallV1(
  //     HTTPMethod.GET,
  //     fileUrl,
  //   )

  //   if(!error && response) {
  //     log.info('Success fetching file list for', fileUrl)
  //     const file = createFileInfo(response)

  //     setFileList(prev => {
  //       // add fetched file to list or start a new list
  //       if(Array.isArray(prev)) return [...prev, file]
  //       else return [file]
  //     })
  //   } else {
  //     log.info('Error fetching file', error)
  //     toast.error(t('common:feedback.error.fetchFailed') || '')
  //   }
  // }

  /**
   * Takes an argument that describes a file / list of files using hyperlinks
   * or a FileInfo object.
   * Returns a list of FileInfo objects.
   * @param files
   */
  async function parseFilesInput(files: FileInfo[] | string[] | string | undefined) {

  }
  // const fetchObjectFiles = async (objectSelf: string) => {
  //   log.debug('fetchObjectFiles', objectSelf)
  //   const [response, error] = await handleAPICallV1(HTTPMethod.GET, objectSelf)

  //   if(!error && response) {
  //     const files = response.results.map((file: any) => createFile(file))
  //     log.debug('files', files)
  //     if(response.next) {
  //       fetchObjectFiles(response.next)
  //     }
  //     setFileList(files)
  //   } else {
  //     toast.error(t('common:feedback.error.fetchFailed') || '')
  //   }
  // }

  const handleDownload = async (url: string) => {
    try {
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', extractFilename(url) || '')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      toast.success(t('common:feedback.success.downloadStarted') || '')
    } catch (error) {
      console.error(error)
      toast.error(t('common:feedback.error.downloadFailed') || '')
    }
  }

  // const handleDeleteFile = async () => {
  //   log.debug('handleDeleteFile', deleteFile)
  //   const [response, error] = await handleAPICallV1(
  //     HTTPMethod.DELETE,
  //     deleteFile?.self,
  //     undefined,
  //     undefined,
  //     "text"
  //   )
  //   if(!error) {
  //     toast.success(t('common:feedback.success.deleteFile') || '')
  //     setOpenDelete(false)
  //     setFileList(fileList!.filter(file => file.self !== deleteFile?.self))
  //   } else {
  //     toast.error(t('common:feedback.error.deleteFile') || '')
  //     setOpenDelete(false)
  //   }
  // }

  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const handleClose = (preventOnClose: boolean = false) => {
    // reset drawer state
    setLoading(false)
    if(fileList !== null) {
      setFileList(
        prev => [...prev!.map(
          file => ({...file, markedAsDeleted: false})
        )]
      )
    }
    if(fileCollections !== null) {
      setFileCollections(prev => [...prev!.map(
        collection => ({...collection, files: collection.files.map(
          file => ({...file, markedAsDeleted: false})
        )})
      )])
    }

    setOpen(false)
    !preventOnClose && onClose()
  }

  const handleConfirmFileChanges = async () => {
    // collect all files
    let allFiles: FileInfo[] = []
    if(fileCollections !== null) {
      fileCollections.forEach(collection => {
        allFiles = [...allFiles, ...collection.files]
      })
    } else if(fileList !== null) {
      allFiles = [...fileList]
    }

    const deletePromises = allFiles
      .filter(file => file.markedAsDeleted && typeof file.self === 'string' && file.self !== '')
      .map(file => handleAPICallV1(
        HTTPMethod.DELETE,
        file.self!,
        undefined,
        undefined,
        'text'
      ))
    const deleteResults = await Promise.allSettled(deletePromises)

    if(deleteResults.every(result => result.status === 'rejected')) {
      toast.error(t('files:feedback.error.savingFileChanges'))
    } else if(deleteResults.some(result => result.status === 'rejected')) {
      toast.warn(t('files:feedback.warn.savingFileChanges'))
    } else {
      toast.success(t('files:feedback.success.savingFileChanges'))
    }

    onConfirm()
  }


  const extractFilename = (url: string) => {
    const urlParts = url?.split('?')[0]
    const pathParts = urlParts?.split('/')
    return pathParts?.pop()
  }
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  if(fileList === null && fileCollections === null) {
    // initialize file render state
    if(files && files.length > 0) {
      if(Object.hasOwn(files[0], 'self')) {
        // files contains a list of FileInfo objects
        setFileList(files as FileInfo[])
      } else if(Object.hasOwn(files[0], 'files')) {
        // files contains list of FileCollection objects
        setFileCollections(files as FileCollection[])
      }
    }
  }
  /* ------------------------- Pre render actions end ------------------------- */

  log.debug(
    'files ->', files,

  )

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <>
      <MultilevelDrawer
        open={open}
        setOpen={setOpen}
        onClose={handleClose}
        size={size}
        title={title ?? ''}
        confirmButtonProps={{
          text: t('common:interaction.button.saveChanges'),
          onConfirm: handleConfirmFileChanges,
          disabled: disableConfirmButton()
        }}
        // customActions={
        //   <Button onClick={() => handleClose()} variant='contained' color='inherit'>
        //     {t('common:interaction.button.cancel')}
        //   </Button>
        // }
      >
        {fileCollections !== null &&
          fileCollections.map((collection, index) => {
            return (
              <Box key={index} mb={3}>
                <Typography variant='h6' mb={2}>{collection.title}</Typography>
                <FilesTable files={{
                    state: collection.files,
                    setState: (files: FileInfo[]) => {
                      setFileCollections((prev: FileCollection[] | null) => {
                        if(prev === null) return null

                        // update file collection
                        prev[index].files = files
                        return [...prev]
                      })
                    }}}
                />
              </Box>
            )
          })
        }
        {fileList !== null &&
          <FilesTable files={{
            state: fileList,
            setState: (files: FileInfo[]) => setFileList(files)
          }}
          />
        }
        <Grid container spacing={2}
          justifyContent="center"
          alignItems="center"
        >
            {/*fileList.length > 0 && !loading && (
              <Grid item xs={12}>
                <TableContainer>
                  <Table>
                    <TableHead className="table-header">
                      <TableRow>
                        <TableCell>{t('common:content.label.fileName')}</TableCell>
                        <TableCell>{t('common:content.label.size')}</TableCell>
                        <TableCell>{t('common:content.label.createdAt')}</TableCell>
                        <TableCell>{t('common:content.label.action')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fileList?.map((file, index) => (
                        <TableRow key={index}>
                          <TableCell>{extractFilename(file.document)}</TableCell>
                          <TableCell>{formatBytes(file.size)}</TableCell>
                          <TableCell>{moment(file.stored_at).format('DD.MM.YYYY HH:mm')}</TableCell>
                          <TableCell align="left">
                            <Stack direction="row" spacing={1}>
                              <Tooltip title={t('common:content.label.download') || ''}>
                                <IconButton onClick={() => handleDownload(file.document)}>
                                  <Download />
                                </IconButton>
                              </Tooltip>
                              {isPrivileged(user, "STAFF") && (
                                <Tooltip title={t('common:content.label.delete') || ''}>
                                  <IconButton onClick={() => {
                                    setOpenDelete(true)
                                    setDeleteFileName(extractFilename(file.document) || '')
                                    setDeleteFile(file)
                                  }}>
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {(file.document.includes(".stl") || file.document.includes(".STL")) && (
                                <Tooltip title={t('common:content.label.showStl') || ''}>
                                  <IconButton
                                    onClick={() => {
                                      setSelectedFile(file)
                                      setOpenStlViewer(true)
                                    }}
                                  >
                                    <ViewInAr />
                                  </IconButton>
                                </Tooltip>
                              )}
                              {file.name.match(/.pdf$/i) &&
                                <Tooltip title={t('common:content.label.showPdf') || ''}>
                                  <IconButton
                                    onClick={() => {
                                      setSelectedFile(file)
                                      setOpenPdfViewer(true)
                                    }}
                                  >
                                    <FileOpenRounded />
                                  </IconButton>
                                </Tooltip>
                              }
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid> */}
            {/* )} */}
            {loading && (
              <Grid item xs={12}>
                <Grid container
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Grid item xs={12}>
                    <CircularProgress
                      sx={{
                        margin: '0 auto',
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <p>{t('common:content.label.loadingFiles') || ''}</p>
                  </Grid>
                </Grid>
              </Grid>
            )}
        </Grid>
      </MultilevelDrawer>
      {/* {openStlViewer &&
        <StlViewerModal
          open={openStlViewer}
          setOpen={setOpenStlViewer}
          url={selectedFile!.document as string}
        />
      }
      {openPdfViewer &&
        <PdfViewerModal
          open={openPdfViewer}
          setOpen={setOpenPdfViewer}
          file={selectedFile!}
        />
      }
      <DeleteModal
        name={deleteFileName}
        open={openDelete}
        setOpen={setOpenDelete}
        onDelete={handleDeleteFile}
      /> */}
    </>
  )
}
