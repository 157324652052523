/* -------------------------- Design imports start -------------------------- */
import { Box, Card, CardActions, CardHeader, IconButton, Modal, Stack, Typography } from "@mui/material"
import Button from "../Button"
import { LoadingButton } from "@mui/lab"
import { ChangeCircleRounded, CheckRounded, CloseRounded, DeleteRounded, DownloadRounded } from "@mui/icons-material"
import PdfViewer from "../../features/PdfHandling/PdfViewer"
import FileInput from "../inputs/FileInput"
import { toast } from "react-toastify"
import DeleteModal from "./DeleteModal"

/* --------------------------- Design imports end --------------------------- */


/* ------------------------ Functional imports start ------------------------ */
import { useState } from "react"
import LogTool from "../../logger/logTools"
import { FileInfo } from "../../utils/types"
import { useTranslation } from "react-i18next"
import { deleteFile, uploadFile} from "../../utils/functions"
import ImageViewer from "./ImageViewer"
import STLViewer from "../../features/StlViewer/StlViewer"
/* ------------------------- Functional imports end ------------------------- */


type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  file: FileInfo
  // providing an onReplaceFile callback function implicitly enables the FileReplacementModal
  onReplaceFile?: (replacedFile: FileInfo, replacementFile: FileInfo) => void
  onClose?: () => void
}



/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export default function FileViewerModal(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {
    open,
    setOpen,
    file,
    onReplaceFile,
    onClose = () => null,
  } = props
  const log = new LogTool({context: 'FileViewerModal', enable: true, logLevel: 'debug'})
  const { t } = useTranslation()
  /* --------------------------- Non state data end --------------------------- */



  /* ---------------------------- Flag states start --------------------------- */
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [waitingOnReplacingFile, setWaitingOnReplacingFile] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */



  /* ---------------------------- Data states start --------------------------- */
  const [fileInputFormState, setFileInputFormState] = useState<any[]>([])
  const [replacementFile, setReplacementFile] = useState<FileInfo | null>(null)
  /* ----------------------------- Data states end ---------------------------- */



  /* ------------------------------ Effects start ----------------------------- */
  // some useEffects...
  // !! Note!!: you should try to avoid setting state in a useEffect as this renders
  // the component twice. For more info read: https://react.dev/learn/you-might-not-need-an-effect
  /* ------------------------------- Effects end ------------------------------ */



  /* ------------------------- Utility functions start ------------------------ */

  /* -------------------------- Utility functions end ------------------------- */



  /* ------------------------ Callback functions start ------------------------ */
  const handleClose = () => {
    // reset modal state
    setOpen(false)
    setFileInputFormState([])
    setReplacementFile(null)

    onClose()
  }

  const handleDownloadFile = async () => {
    try {
      const link = document.createElement('a')
      link.href = file.document!
      link.setAttribute('download', file.name! || '')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      toast.success(t('common:feedback.success.downloadStarted') || '')
    } catch (error) {
      console.error(error)
      toast.error(t('common:feedback.error.downloadFailed') || '')
    }
  }

  const handleDeleteFile = async () => {
    await deleteFile({
      url: file.self!,
      onSuccess: () => {
        toast.success(t('common:feedback.success.deleteFile') || '')
      },
      onError: (error) => {
        toast.error(t('common:feedback.error.deleteFile') || '')
      }
    })
  }

  const handleConfirmReplacementFile = () => {
    if(replacementFile === null) return

    // determine the upload url
    let prefix = ''
    if('article' in file) {
      prefix = file.article!
    }
    if('contract' in file) {
      prefix = file.contract!
    }
    if('request' in file) {
      prefix = file.request!
    }
    if('guestRequest' in file) {
      prefix = file.guestRequest!
    }
    if(prefix === '') {
      throw new Error(
        'Failed to automatically determine the upload url for the replacement file. You might need to '
        + 'add another test-case to the upload url prefix detection.'
      )
    }

    log.info('Begin replacing file', file.name!)
    setWaitingOnReplacingFile(true)

    deleteFile({url: file.self!})
    uploadFile({
      url: prefix + 'files/',
      file: fileInputFormState[0],
      onSuccess: (replacementFile: FileInfo) => {
        setFileInputFormState([])
        setReplacementFile(replacementFile)
        setWaitingOnReplacingFile(false)

        // execute callback
        onReplaceFile!(file, replacementFile)

        toast.success(t('files:feedback.success.uploadingReplacementFile'))
        handleClose()
      },
      onError: (error: any) => {
        setWaitingOnReplacingFile(false)
        toast.error(t('files:feedback.error.uploadingReplacementFile'))
        handleClose()
      }
    })
  }
  /* ------------------------- Callback functions end ------------------------- */



  /* ------------------------- Render constants start ------------------------- */
  const [_, fileExt] = file.name!.split('.')
  const [__, replacementFileExt] = replacementFile?.name?.split('.') || [undefined, undefined]
  /* -------------------------- Render constants end -------------------------- */



  /* ------------------------ Pre render actions start ------------------------ */
  if(fileInputFormState.length === 1 && replacementFile === null) {
    // we need a FileReader instance in order to receive the path of the replacement
    // file that is located on the users system.
    const fr = new FileReader()
    fr.readAsDataURL(fileInputFormState[0])
    fr.onload = function(e) {
      const fileUrl = this.result
      if(typeof fileUrl === 'string') {
        setReplacementFile({
          name: fileInputFormState[0].name,
          document: fileUrl,
        })
      }
    }
  }

  if(fileInputFormState.length === 2 && replacementFile !== null) {
    // the user uploaded a second file. They probably wanted to choose a different file
    // as the replacement file -> reset replacementFile and fileInputFormState
    // to set the newly uploaded file as the replacement file on the next render.
    setReplacementFile(null)
    setFileInputFormState(prev => [prev[1]]) // set the second file as the first and only file
  }
  /* ------------------------- Pre render actions end ------------------------- */


  log.debug(
    'file ->', file,
    'fileInputFormState ->', fileInputFormState,
    'replacementFile ->', replacementFile,
  )


  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  return (
    <>
      <Modal
        open={open}
        onClose={(_: any) => handleClose()}
      >
        <Card
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90vw',
            height: '97vh',
            overflow: 'hidden',
          }}
        >
          <CardHeader
            title={<Typography variant='h5'>{file.name}</Typography>}
            action={
              <IconButton onClick={(_: any) => handleClose()}>
                <CloseRounded />
              </IconButton>
            }
          />
          <Box
            id='cardContent'
            display='flex'
            flexDirection='column'
            height='calc(100% - (40px + 2rem))' // modal height minus card header height
            p='16px'
          >
            <Box id='fileReplacementActions' display='flex' justifyContent='space-between' mb='1rem'>
              <Stack id='fileActions' direction='row' spacing={1}>
                <Button variant='outlined' color='primary' size='small' startIcon={<DownloadRounded/>}
                  onClick={handleDownloadFile}
                >
                  {t('common:content.label.download')}
                </Button>
                <Button variant='outlined' color='primary' size='small' startIcon={<DeleteRounded/>}
                  onClick={() => setOpenDeleteModal(true)}
                >
                  {t('common:interaction.button.delete')}
                </Button>
              </Stack>
              {replacementFile !== null && (
                <LoadingButton
                  loading={waitingOnReplacingFile}
                  variant='outlined' color='primary' size='small' startIcon={<CheckRounded/>}
                  onClick={handleConfirmReplacementFile}
                  disabled={replacementFile === null}
                  >
                  {t('files:interaction.button.confirmReplacementFile')}
                </LoadingButton>
              )}
            </Box>
            <Box
              id='fileComparisonContainer'
              flexGrow={1}
              minHeight={0} // see https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container
            >
              <Box // a flex container to manage the horizontal placement of the file viewers within the fileComparisonContainer
                display='flex'
                flexDirection='row'
                height='100%'
              >
                <Box flexGrow={1} minWidth='50%'>
                  {fileExt.match(/pdf/i) && <PdfViewer file={file} />}
                  {fileExt.match(/(?:png|jpg|jpeg)/i) && <ImageViewer file={file}/>}
                  {/* TODO integrate STLViewer */}
                </Box>
                {(typeof onReplaceFile !== 'undefined' && replacementFile !== null) && (
                  // display the replacement file right next to the file that is to be
                  // replaced such that the user can ensure they use the correct file
                  // as a replacement to the currently selected/opened file
                  <Box flexGrow={1} minWidth='50%' pl='1rem'>
                    {replacementFileExt === 'pdf' && <PdfViewer file={replacementFile} />}
                    {replacementFileExt!.match(/(?:png|jpg|jpeg)/i) && <ImageViewer file={file}/>}
                    {fileExt.match(/(?:stl)/i) && <STLViewer url={file.document!}/>}
                  </Box>
                )
                }
              </Box>
            </Box>
            {typeof onReplaceFile !== 'undefined' &&
              <>
                <Typography
                  variant='h6'
                  mt='2rem'
                  >
                  {t('files:content.heading.uploadReplacementFile')}
                </Typography>
                <FileInput
                  inputState={{
                    state: fileInputFormState,
                    setState: setFileInputFormState,
                  }}
                  maxAllowedFiles={2} // see the pre render actions, why we need to allow the upload of 2 files
                  sx={{height: 'unset'}}
                />
              </>
            }
          </Box>
        </Card>
      </Modal>
      {openDeleteModal &&
        <DeleteModal
          name={""}
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          onDelete={async () => {
            await handleDeleteFile()
            setOpenDeleteModal(false)
            handleClose()
          }}
        />
      }
    </>
  )
}
