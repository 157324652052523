/* -------------------------- Design imports start -------------------------- */
import { FormField } from './FormField'
import { Grid } from '@mui/material'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
import {
  getManProcessData,
  getPropertyByString,
  setManProcessData,
  setPropertyByString,
} from '../utils/functions'
import { FieldObject, FormFieldType, FormObject, ValueOf } from '../utils/types'
import LogTool from '../../../logger/logTools'
/* ------------------------- Functional imports end ------------------------- */

interface Props {
  editing?: boolean
  formObject: FormObject
  formFields: FormFieldType[]
  onChange: (object: FormObject) => void
  sx?: React.CSSProperties
}

/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */
export function Form(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { editing, formObject, formFields, sx = { mb: 1 } } = props
  const log = new LogTool({context: 'Form', enable: true, logLevel: 'warn'})
  /* --------------------------- Non state data end --------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  const onChange = (key: keyof FormObject) => (value: ValueOf<FormObject>) => {
    if (key.toString().includes('manufacturing_process_data')) {
      props.onChange(setManProcessData(formObject, key as string, value))
    } else {
      props.onChange(setPropertyByString(formObject, key as string, value))
    }
  }

  const value = (key: keyof FormObject) => {
    if (key.toString().includes('manufacturing_process_data')) {
      return getManProcessData(formObject, key as string) as ValueOf<FormObject>
    } else {
      return getPropertyByString(formObject, key as string) as ValueOf<FormObject>
    }
  }
  /* ------------------------- Callback functions end ------------------------- */

  log.debug("formFields ->", formFields)

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <Grid item xs={12} sx={sx}>
      <form>
        <Grid container spacing={1.2}>
          {formFields.map(({ xs = 12, sm = 6, md = 6, lg = 6, xl = 6, ...field }) => (
            <Grid sx={sx} item xs={xs} sm={sm} md={md} lg={lg} xl={xl} key={field.key}>
              <FormField
                field={field as FieldObject}
                value={value(field.key)}
                onChange={onChange(field.key)}
                disabled={!editing || (field.type !== 'custom' && field.disabled)}
                required={field.required}
              />
            </Grid>
          ))}
        </Grid>
      </form>
    </Grid>
  )
}
