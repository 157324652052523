/* -------------------------- Design imports start -------------------------- */
import InfoPanel from '../../../components/layout/InfoPanel';
import {  Chip, Grid, Stack } from '@mui/material';
import InfoField from '../../../components/inputs/InfoField';
import { Check, Close } from '@mui/icons-material';
import Button from '../../../components/Button';
import { Button as MUIButton } from '@mui/material';
import { Table } from 'antd';
/* --------------------------- Design imports end --------------------------- */


/* ------------------------ Functional imports start ------------------------ */
import LogTool from '../../../logger/logTools';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useUserContext } from '../../../utils/context';
import { getPKfromSelf, isPrivileged } from '../../../utils/functions';
import { useHistory } from 'react-router-dom';
/* ------------------------- Functional imports end ------------------------- */

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setOpenFilesDrawer: (open: boolean) => void;
  infoData: any;
  title?: string;
  disabled?: boolean;
}

export default function RequestInfoPanel(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const { open, setOpen, setOpenFilesDrawer, infoData, title = '', disabled = false } = props
  const log = new LogTool({ context: 'RequestInfoPanel', enable: true, logLevel: 'debug' })
  const { t } = useTranslation()
  const { user } = useUserContext()
  const history = useHistory()

  log.debug("Open -> ", open)
  log.debug("infoData -> ", infoData)
  /* --------------------------- Non state data end --------------------------- */

  /* ---------------------------- Flag states start --------------------------- */
  /* ----------------------------- Flag states end ---------------------------- */

  /* ---------------------------- Data states start --------------------------- */
  /* ----------------------------- Data states end ---------------------------- */

  /* ------------------------------ Effects start ----------------------------- */
  /* ------------------------------- Effects end ------------------------------ */

  /* ------------------------- Utility functions start ------------------------ */
  /* -------------------------- Utility functions end ------------------------- */

  /* ------------------------ Callback functions start ------------------------ */
  /* ------------------------- Callback functions end ------------------------- */

  /* ------------------------- Render constants start ------------------------- */
  const renderStatus = (status: number) => {
    switch(status) {
        case 0:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("request:content.label.pending")} color="warning" variant="outlined" />
                </Stack>
            )
        case 1:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("request:content.label.accepted")} color="success" variant="outlined" />
                </Stack>
            )
        case 2:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("request:content.label.rejected")} color="error" variant="outlined" />
                </Stack>
            )
        case 3:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("request:content.label.forwarded")} color="primary" variant="outlined" />
                </Stack>
            )
        default:
            return (
                <Stack direction="row" spacing={1}>
                    <Chip label={t("request:content.label.unknown")} color="default" variant="outlined" />
                </Stack>
            )
    }
  }
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  /* ------------------------- Pre render actions end ------------------------- */

  log.debug(
    'infoData ->', infoData,
  )

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  log.debug("InfoData -> ", infoData)
  return (
    <InfoPanel
      open={open}
      setOpen={setOpen}
      label={t("common:content.label.requestInformation")}
      allowEdit={false}
      disabled={true}
    >
      <Grid container spacing={2} style={{ padding: "20px" }}>
        <Grid item xs={12}>
          <InfoField
            label={t("request:content.label.requestNumber")}
            fullWidth
            value={infoData?.self && getPKfromSelf(infoData.self)}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.article")}
            fullWidth
            value={infoData?.article?.name}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.createdAt")}
            fullWidth
            value={moment(infoData?.createdAt).format('DD.MM.YYYY')}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.updatedAt")}
            fullWidth
            value={moment(infoData?.updatedAt).format('DD.MM.YYYY')}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.remarks")}
            fullWidth
            value={infoData?.remarks}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.certificates")}
            fullWidth
            value={infoData?.certificate}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.description")}
            fullWidth
            value={infoData?.description}
          />
        </Grid>
        {isPrivileged(user, "STAFF") ? (
          <>
            <Grid item xs={12}>
              <InfoField
                label={t("request:content.label.calculatedCosts")}
                fullWidth
                value={infoData?.quantities &&
                  <Table
                    columns={[
                      {
                        title: t("common:content.label.quantity"),
                        dataIndex: "quantity",
                        key: "quantity"
                      },
                      {
                        title: t("request:content.label.calculatedCosts"),
                        dataIndex: "calculatedCosts",
                        key: "calculatedCosts"
                      }
                    ]}
                    dataSource={Object.keys(infoData.quantities).map((key: string) => {
                      const quantity = infoData.quantities[key]
                      const calculatedCosts = infoData.calculatedCosts[quantity]
                        ? infoData.calculatedCosts[quantity]
                        : t("request:content.label.costsUnknown")
                      return {
                        key: key,
                        quantity: quantity + " Stk.",
                        calculatedCosts: calculatedCosts + " €"
                      }
                    })}
                    pagination={false}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <InfoField
                label={t("request:content.label.reportedCosts")}
                fullWidth
                value={infoData?.quantities &&
                  <Table
                    columns={[
                      {
                        title: t("common:content.label.quantity"),
                        dataIndex: "quantity",
                        key: "quantity"
                      },
                      {
                        title: t("request:content.label.reportedCosts"),
                        dataIndex: "reportedCosts",
                        key: "reportedCosts"
                      }
                    ]}
                    dataSource={infoData.quantities.map((quantity: number) => {
                      let lowestReportedCosts: number | undefined = undefined;
                      for(const supplier of Object.keys(infoData.reportedCosts)) {
                        if(typeof lowestReportedCosts === 'undefined'
                          || lowestReportedCosts > infoData.reportedCosts[supplier][quantity]
                        ) {
                          lowestReportedCosts = infoData.reportedCosts[supplier][quantity]
                        }
                      }
                      return {
                        key: `reported_costs_${quantity}`,
                        quantity: quantity + " Stk.",
                        reportedCosts: typeof lowestReportedCosts !== 'undefined'
                          ? `ab ${lowestReportedCosts}`
                          : t("request:content.label.costsUnknown")
                      }
                    })}
                    pagination={false}
                  />
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant='contained'
                onClick={
                  // TODO: forward to details Page -> implement pretty details page with actual functionality
                  () => history.push('/contracts/requests/'+infoData.key)
                }
              >
                {t("common:interaction.button.showDetails")}
              </Button>
            </Grid>
          </>
        ) : (
          <Grid item xs={12}>
            <InfoField
              label={t("request:content.label.quantities")}
              fullWidth
              value={infoData?.quantities.join(",")}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.wishDate")}
            fullWidth
            value={moment(infoData?.wishDate).format('DD.MM.YYYY')}
          />
        </Grid>
        <Grid item xs={12}>
          <InfoField
            label={t("common:content.label.status")}
            fullWidth
            value={renderStatus(infoData?.status)}
          />
        </Grid>
        {isPrivileged(user, "STAFF") && (<Grid item xs={12}>
          <InfoField
            label={t("common:content.label.priority")}
            fullWidth
            value={infoData?.priority ? <Check /> : <Close />}
          />
        </Grid>)}
        {isPrivileged(user, "STAFF") && (<Grid item xs={12}>
          <InfoField
            label={t('common:content.label.editors')}
            fullWidth
            value={""}
          />
          {infoData?.editors && infoData.editors.length > 0 && infoData.editors.map((editor: any) => {
            return <Chip key={editor.id} label={`${editor.first_name} ${editor.last_name}`} sx={{marginLeft: "10px"}} />
          })}
        </Grid>)}
        <Grid item xs={12}>
          <Button
            id="showFilesButton"
            fullWidth
            variant="contained"
            onClick={() => {
              setOpenFilesDrawer(true)
            }}
          >
            {t('common:interaction.button.showFiles')}
          </Button>
        </Grid>
        {(isPrivileged(user, 'STAFF') && ['AS_1', 'Gesamtpalette Terminal W'].includes(infoData?.article?.name)) &&(
          <Grid item xs={12}>
            <MUIButton
              id="showRequestDetailsButton"
              fullWidth
              variant="outlined"
              sx={{textTransform: "none"}}
              onClick={() => {
                history.push(`contracts/requests/${getPKfromSelf(infoData.self)}`)
              }}
            >
              {t('common:interaction.button.showDetails')}
            </MUIButton>
          </Grid>
        )}
      </Grid>
    </InfoPanel>
  )
}