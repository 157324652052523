/* -------------------------- Design imports start -------------------------- */
import {  Chip, CircularProgress, Grid, IconButton, Typography } from '@mui/material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { Space } from 'antd'
/* --------------------------- Design imports end --------------------------- */
/* ------------------------ Functional imports start ------------------------ */
import React, { useEffect, useRef, useState } from 'react'
import { deleteGuestRequests, fetchGuestRequests } from './utils/functions'
import { GuestRequest } from './utils/types'
import GetConstant from './utils/constants'
import { useTranslation } from 'react-i18next'
import Table, { ColumnProps } from 'antd/es/table'
import FilterComponent, { FilterOptions } from '../../components/widgets/FilterMenu'
import { DeleteOutlined, ShareRounded } from '@mui/icons-material'
import DeleteModal from '../../components/widgets/DeleteModal'
import { toast } from 'react-toastify'
import LogTool from '../../logger/logTools'
import ColumnSelector from '../../components/widgets/ColumnSelector'
import UnauthRequestDrawer from './UnauthRequestDrawer'
import FilesDrawer from '../../components/FilesDrawer'
import Button from '../../components/Button'
import LoadingSkeleton from '../../components/widgets/LoadingSkeleton'
import { useCountContext } from '../../utils/context'
import CreateGuestRequestSharepointDrawer from './CreateGuestRequestSharepointDrawer'
import { inputToAPISharepointJSON } from '../Item/utils/functions'
import { handleAPICallV1 } from '../../utils/functions'
import { FileInfo, HTTPMethod } from '../../utils/types'
import { Sharepoint } from '../Item/utils/types'
/* ------------------------- Functional import end ------------------------- */
type ColumnsType = Array<ColumnProps<any>>

type Props = {
  openInfoPanel: boolean
  setOpenInfoPanel: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedGuestRequest: React.Dispatch<React.SetStateAction<GuestRequest | undefined>>
  openFilesDrawer: boolean
  setOpenFilesDrawer: React.Dispatch<React.SetStateAction<boolean>>
  selectedGuestRequest: GuestRequest | undefined
}

/* -------------------------------------------------------------------------- */
/*                               Start component                              */
/* -------------------------------------------------------------------------- */
export default function UnauthRequestOverviewPage(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const log = new LogTool({ context: 'UnauthRequestOverviewPage', enable: true, logLevel: 'debug' })
  const { t } = useTranslation()
  const {
    openInfoPanel,
    setOpenInfoPanel,
    setSelectedGuestRequest,
    openFilesDrawer,
    setOpenFilesDrawer,
    selectedGuestRequest,
  } = props
  const columnsData: ColumnsType = GetConstant({ name: 'guestRequestTableColumns' }) as ColumnsType
  const { initialGuestRequests } = useCountContext()
  /* --------------------------- Non state data end --------------------------- */
  /* ---------------------------- Flag states start --------------------------- */
  const [loading, setLoading] = useState(false)
  const [filterVisible, setFilterVisible] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [openShareDrawer, setOpenShareDrawer] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */
  /* --------------------------- Data states start ---------------------------- */
  const dataSource = useRef<GuestRequest[]>([])
  const [myFilterOptions, setMyFilterOptions] = useState<FilterOptions>({})
  const [filteredData, setFilteredData] = useState<GuestRequest[]>([])
  const [defaultColumns] = useState([
    "read",
    'name',
    'email',
    'company',
    'phone',
    'quantity',
    'description',
    'status',
    'editors',
    'createdAt',
  ])
  const [columns] = useState<ColumnsType>(columnsData)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [guestRequestFormInput, setGuestRequestFormInput] = useState<any>({})
  const [guestRequestSharepointFormInput, setGuestRequestSharepointFormInput] = useState<any>({})
  const [totalGuestRequests, setTotalGuestRequests] = useState<number>(0)
  const [selectedGuestRequests, setSelectedGuestRequests] = useState<GuestRequest[]>([])
  const [newGuestRequests, setNewGuestRequests] = useState<GuestRequest[]>(initialGuestRequests)
  /* ---------------------------- Data states end ----------------------------- */
  /* ------------------------------ Effects start ----------------------------- */
  useEffect(() => {
    setLoading(true)
    fetchGuestRequests({
      onSuccess: (data: GuestRequest[], count: any) => {
        dataSource.current = [...dataSource.current, ...data]
        setTotalGuestRequests(count)
        setFilteredData(dataSource.current)
        setLoading(false)
      },
      onError: error => {
        log.error('Error fetching Guest Requests -> ', error)
        setLoading(false)
      },
      parameter: ['expand=files,editors'],
    })
  }, [])

  /* ------------------------------- Effects end ------------------------------ */
  /* ------------------------- Utility functions start ------------------------ */
  const filterData = (data: any[], filterOptions: { [x: string]: any }) => {
    if (!data || !Array.isArray(data)) {
      return [] // Rückgabe eines leeren Arrays, wenn die Daten ungültig sind
    }

    return data.filter(d => {
      return Object.keys(filterOptions).every(key => {
        const filterValues = filterOptions[key]
        if (!filterValues.length) return true // Kein Filter angewendet
        if (key === 'name') {
          return filterValues.some((value: string) =>
            d.name.toLowerCase().includes(value.toLowerCase())
          )
        } else if (key === 'quantity') {
          return filterValues.some((value: string) => parseInt(d.in_storage) >= parseInt(value))
        } else {
          const cellValue = d[key]
          return filterValues.some((value: string) =>
            cellValue?.toString().toLowerCase().includes(value.toLowerCase())
          )
        }
      })
    })
  }
  /* -------------------------- Utility functions end ------------------------- */
  /* ------------------------ Callback functions start ------------------------ */
  const handleCreateGuestRequestSharepoint = async (sharepointInput: any) => {
    log.info('Begin creating sharepoint for guest request')
    const json = inputToAPISharepointJSON(sharepointInput)
    log.debug('Sharepoint JSON ->', json)
    const [response, error] = await handleAPICallV1(
      HTTPMethod.POST,
      'items/sharepoints/',
      undefined,
      json
    )

    if(!error && response) {
      log.info('Success creating sharepoint for guest request')
      return response as Sharepoint
    } else {
      log.error('Error creating sharepoint for guest request')
      return 'ERROR'
    }
  }
  const handleApplyFilter = (filterOptions: Record<string, any>) => {
    setMyFilterOptions(filterOptions)

    const filteredProducts = dataSource.current.filter(d => {
      return Object.keys(filterOptions).every(key => {
        const filterValues = filterOptions[key]
        if (!filterValues.length) return true // no filter applied

        const cellValue = d[key as keyof GuestRequest]
        if (cellValue === null) return null // Ignore null values
        if (typeof cellValue === 'string') {
          // String-based filtering
          if (typeof cellValue === 'string') {
            return filterValues.some((value: string) =>
              cellValue.toLowerCase().includes(value.toLowerCase())
            )
          }
        } else if (typeof cellValue === 'number') {
          // Number-based filtering
          const cellValueAsNumber =
            typeof cellValue === 'string' ? parseFloat(cellValue) : cellValue
          return filterValues.some((value: number) => cellValueAsNumber == value)
        }

        return true // Ignore other data types or no match
      })
    })

    setFilteredData(filteredProducts)
    setFilterVisible(false)
  }

  const handleColumnChange = (checkedColumns: string[]) => {
    setVisibleColumns(checkedColumns)
  }

  const handleCancelFilter = () => {
    setFilterVisible(false)
  }

  const handleReset = () => {
    setMyFilterOptions({})
    setFilteredData(dataSource.current)
  }

  const handleShowFilter = () => {
    setFilterVisible(true)
  }
  /* ------------------------- Callback functions end ------------------------- */
  /* ------------------------- Render constants start ------------------------- */
  const guestRequestTableColumns: any = GetConstant({ name: 'guestRequestTableColumns', newGuestRequests: newGuestRequests })
  const [visibleColumns, setVisibleColumns] = useState<string[]>(defaultColumns)
  const filteredColumns = guestRequestTableColumns.filter((column: any) => {
    return visibleColumns.includes(column.key?.toString() ?? '')
  })
  const chips = Object.entries(myFilterOptions).map(([key, values]) => {
    if (!values.length) return <></>
    return (
      <Chip
        key={key}
        label={`${t('common:content.label.' + key)}: ${values}`}
        onDelete={() => {
          setMyFilterOptions(prev => {
            const updatedOptions = { ...prev, [key]: [] }
            setFilteredData(filterData(dataSource.current, updatedOptions))
            return updatedOptions
          })
        }}
      />
    )
  })

  const buttonContainerStyle = {
    marginLeft: openInfoPanel ? '-270px' : '0',
    transition: 'margin-left 0.3s ease-in-out',
  }
  /* -------------------------- Render constants end -------------------------- */

  /* ------------------------ Pre render actions start ------------------------ */
  let guestRequestFiles: FileInfo[] = []
  if(selectedGuestRequest) {
    // making sure that the guest request files are provided with the guest request
    if(typeof selectedGuestRequest.files === 'string'
       || typeof selectedGuestRequest.files === 'undefined')
    {
      throw new Error(
        `Guest request ${selectedGuestRequest.key} does not provide the guest request `
        + 'files. The files of a guest request are required. Make sure to expand them '
        + 'when fetching the guest requests.'
      )
    }

    guestRequestFiles = selectedGuestRequest.files
  }
  /* ------------------------- Pre render actions end ------------------------- */


  log.debug(
    'selectedGuestRequest.files ->', selectedGuestRequest,
    'guestRequestFiles ->', guestRequestFiles,
  )


  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  if(loading) return <LoadingSkeleton pagetitle='' />

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>{chips}</Grid>
        </Grid>
        <Grid container spacing={1} justifyContent="flex-end" style={buttonContainerStyle}>
          <Grid item>
            <Space>
              <Button
                id="editRequestButton"
                size="small"
                variant="outlined"
                color='primary'
                onClick={() => {
                  setGuestRequestFormInput(selectedGuestRequest)
                  setOpenDrawer(true)
                }}
                disabled={selectedRowKeys.length !== 1}
              >
                {t('common:interaction.button.editRequest')}
              </Button>
            </Space>
          </Grid>
          <Grid item>
            <Space>
              <Button
                id="deleteRequestButton"
                size="small"
                variant="outlined"
                color='primary'
                startIcon={<DeleteOutlined />}
                onClick={() => setOpenDelete(true)}
                disabled={selectedRowKeys.length < 1}
              >
                {t('request:interaction.button.deleteRequest')}
              </Button>
            </Space>
          </Grid>
          <Grid item>
            <Space>
              <Button
                id="filterButton"
                size="small"
                variant="outlined"
                color='primary'
                startIcon={<FilterAltOutlinedIcon />}
                onClick={handleShowFilter}
              >
                {t('common:content.label.filter')}
              </Button>
            </Space>
          </Grid>
          <Grid item>
            <ColumnSelector
              columns={columns as Array<{ key: string; title: string }>}
              onChangeColumns={handleColumnChange}
              defaultColumns={defaultColumns}
            />
          </Grid>
          <Grid item>
            <IconButton
              aria-label="share"
              disabled={selectedGuestRequests.length !== 1}
              onClick={() => setOpenShareDrawer(true)}
            >
              <ShareRounded />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Table
          columns={filteredColumns}
          dataSource={filteredData}
          pagination={{
            total: totalGuestRequests,
            defaultPageSize: 10,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
          }}
          locale={{
            emptyText: filteredData.length === 0 ? t('common:content.label.noData') : <CircularProgress />,
          }}
          rowSelection={{
            type: 'checkbox', // Set to 'checkbox' for multiple row selection
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedKeys: React.Key[], selectedRows: GuestRequest[]) => {
              setSelectedRowKeys(selectedKeys)
              setSelectedGuestRequests(selectedRows)
            },
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                setSelectedGuestRequest(record as GuestRequest)
                const newGuestRequest = newGuestRequests.find(guestRequest => guestRequest.key === record.key)
                if(newGuestRequest && !newGuestRequest.read) {
                  setNewGuestRequests(prev => prev.map(guestRequest => {
                    if(guestRequest.key === record.key) {
                      return {...guestRequest, read: true}
                    }
                    return guestRequest
                  }))
                }
                if (selectedRowKeys.includes(record.key as React.Key)) {
                  setSelectedRowKeys(selectedRowKeys.filter(key => key !== record.key))
                  setSelectedGuestRequests(
                    selectedGuestRequests.filter(guestRequest => guestRequest.key !== record.key)
                  )
                  setSelectedGuestRequest(undefined)
                  setOpenInfoPanel(false)
                } else {
                  setSelectedRowKeys([...selectedRowKeys, record.key as React.Key])
                  setSelectedGuestRequests([...selectedGuestRequests, record as GuestRequest])
                  setSelectedGuestRequest(record as GuestRequest)
                  setOpenInfoPanel(true)
                }
                /* fetchGuestRequestFiles(
                    {
                        onSuccess: (data) => {
                            console.log(data)
                        },
                        onError: (error) => {
                            console.log(error)
                        },
                        url: record.self
                    }
                  )
                */
              },
            }
          }}
        />
        {filterVisible && (
          <FilterComponent
            columns={guestRequestTableColumns}
            onApplyFilter={handleApplyFilter}
            onCancelFilter={handleCancelFilter}
            onResetFilter={handleReset}
            filterOptions={myFilterOptions}
          />
        )}
      </Grid>
      <DeleteModal
        open={openDelete}
        setOpen={setOpenDelete}
        onDelete={() => {
          deleteGuestRequests(
            selectedGuestRequests,
            () => {
              toast.success(t('request:feedback.success.deleteRequestSuccess'))
              setSelectedRowKeys([])
              setSelectedGuestRequests([])
              setFilteredData(prev => prev.filter(d => !selectedGuestRequests.includes(d)))
              setOpenDelete(false)
            },
            (error: any) => {
              log.error(error)
              toast.error(t('request:feedback.error.deleteRequestFailed'))
              setOpenDelete(false)
            }
          )
        }}
        name={t('common:content.label.requests')}
      />
      <UnauthRequestDrawer
        open={openDrawer}
        setOpen={setOpenDrawer}
        formState={{ state: guestRequestFormInput, setState: setGuestRequestFormInput }}
        onClose={() => {
          setSelectedRowKeys([])
          setSelectedGuestRequests([])
          setOpenInfoPanel(false)
          setSelectedGuestRequest(undefined)
        }}
        dataSource={dataSource}
        setFilteredData={setFilteredData}
      />
      {openFilesDrawer &&
        <FilesDrawer
          open={openFilesDrawer}
          setOpen={setOpenFilesDrawer}
          onClose={() => {}}
          files={guestRequestFiles}
          title={t('common:content.label.requestFiles') as string}
        />
      }
      {selectedGuestRequest &&
        <CreateGuestRequestSharepointDrawer
          open={openShareDrawer}
          setOpen={setOpenShareDrawer}
          formState={{
            state: guestRequestSharepointFormInput,
            setState: setGuestRequestSharepointFormInput,
          }}
          selectedGuestRequest={selectedGuestRequest}
          onConfirm={handleCreateGuestRequestSharepoint}
        />
      }
    </Grid>
  )
}
