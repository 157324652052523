/* -------------------------- Design imports start -------------------------- */
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material"
import MultilevelDrawer from "../../../components/layout/MultilevelDrawer"
import { AddRounded, DeleteRounded, EditRounded } from "@mui/icons-material"
import { toast } from "react-toastify"
/* --------------------------- Design imports end --------------------------- */


/* ------------------------ Functional imports start ------------------------ */
import LogTool from "../../../logger/logTools"
import { useRef, useState } from "react"
import { FieldObject, FormFieldType, KeysOfFieldObject } from "../../Form"
import { useTranslation } from "react-i18next"
import { EditorFieldObject } from "../utils/types"
import { cleanUpFormFields, generateAddFormFieldButton, generateTextField } from "../utils/functions"
/* ------------------------- Functional imports end ------------------------- */


type Props = {
  open: boolean
  setOpen: (open: boolean) => void
  // formState: {
  //   state: any | undefined
  //   setState: React.Dispatch<React.SetStateAction<any | undefined>>
  // },
  formTitleSuggestion?: string
  formFieldSuggestion?: EditorFieldObject[]
  onClose?: () => void
  onConfirm: (input: any) => Promise<"SUCCESS" | "ERROR">
}


/* -------------------------------------------------------------------------- */
/*                               Start Component                              */
/* -------------------------------------------------------------------------- */

export default function FormEditorDrawer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const log = new LogTool({context: "FormEditorDrawer", enable: true, logLevel: 'warn'})
  const uniqueKeyStore = useRef(0)
  const { t } = useTranslation()
  const {
    open,
    setOpen,
    // formState,
    formTitleSuggestion = t('common:content.label.feedbackForm') as string,
    formFieldSuggestion = [
      generateTextField(getUniqueKey(), t('common:content.label.unnamedField') as string,),
      generateAddFormFieldButton(),
    ],
    onClose = () => null,
    onConfirm,
  } = props

  /* --------------------------- Non state data end --------------------------- */



  /* ---------------------------- Flag states start --------------------------- */
  const [openFieldObjMenu, setOpenFieldObjMenu] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */



  /* ---------------------------- Data states start --------------------------- */
  const [formJson, setFormJson] = useState<{formTitle: string, formFields: EditorFieldObject[]}>({
    formTitle: formTitleSuggestion,
    formFields: formFieldSuggestion,
  })
  const [selectedFormObj, setSelectedFormObj] = useState<(EditorFieldObject)>()
  const [formFieldMenuAnchor, setFormFieldMenuAnchor] = useState<null | HTMLElement>(null)
  /* ----------------------------- Data states end ---------------------------- */



  /* ------------------------------ Effects start ----------------------------- */

  /* ------------------------------- Effects end ------------------------------ */



  /* ------------------------- Utility functions start ------------------------ */
  /**
   * Generates a unique element key (ascending number).
   * @returns
   */
  function getUniqueKey(): string {
    const key = uniqueKeyStore.current
    uniqueKeyStore.current += 1
    return String(key)
  }

  function getFieldObjIndex(
    formField: EditorFieldObject,
  ): number {
    return formJson.formFields.indexOf(formField)
  }

  /**
   * Helper function that updates the selectedFormObj and formJson at once. This
   * makes the formFieldMenu interactive: the edited formField changes in real time.
   * @param prop The formField property to update.
   * @param value The value to apply to the formField property.
   */
  function updateFieldObj(fieldObj: EditorFieldObject, prop: KeysOfFieldObject, value: any) {
    // get the index of the selectedFormObj before updating the porperty
    const i = getFieldObjIndex(fieldObj)

    // get an un-typed copy of the formField. This enables us to add any prop to it
    let updatedFormField = {
      ...fieldObj,
    } as {[key: string]: any}

    // update the property
    updatedFormField[prop] = value


    setSelectedFormObj(updatedFormField as EditorFieldObject)
    setFormJson(prev => {
      prev.formFields[i] = updatedFormField as EditorFieldObject
      return {
        ...prev,
        formFields: prev.formFields
      }
    })
  }
  /* -------------------------- Utility functions end ------------------------- */



  /* ------------------------ Callback functions start ------------------------ */
  const handleClose = (preventOnClose?: boolean) => {
    // reset FormEditor
    uniqueKeyStore.current = 0
    setFormJson({
      formTitle: formTitleSuggestion,
      formFields: formFieldSuggestion,
    })

    !preventOnClose && onClose()
  }

  const handleConfirm = async () => {
    // post request to server and wait for response
    const confirmStatus = await onConfirm({
      formTitle: formJson.formTitle,
      formJson: cleanUpFormFields(formJson.formFields)
    })
    // {
    //   ...formState.state,
    //   // if the formTitle was not changed, formState.state.formTitle is undefined -> provide the default formTitle
    //   ...((!formState.state || typeof formState.state.formTitle === 'undefined') && {formTitle: defaultFormTitle}),
    //   formJson: cleanUpFormJson(formJson),
    // }

    if(confirmStatus === "SUCCESS") {
      toast.success(t('common:feedback.success.creatingFeedbackForm'))
      handleClose(true)
    } else {
      toast.error('common:feedback.error.creatingFeedbackForm')
    }
  }

  const handleAddFormField = () => {
    setFormJson(prev => {
      // replace the last element with a new textfield
      prev.formFields[prev.formFields.length - 1] = {
        key: getUniqueKey(),
        label: t('common:content.label.unnamedField'),
        type: 'text',
        value: null,
        onChange: () => null,
      }
      // add 'addFormField' entry to the end of formJson
      return {
        ...prev,
        formFields: [...prev.formFields, {type: 'addFormField'}]
      }
    })
  }

  const handleDeleteFormField = (formField: EditorFieldObject) => {
    // get a copy of the current formJson as so that we do not have to work on an array stored in a state variable
    const updatedFormFields = [...formJson.formFields]

    const i = getFieldObjIndex(formField)

    // delete formField
    updatedFormFields.splice(i, 1)
    setFormJson(prev => ({...prev, formFields: updatedFormFields}))
  }

  const handleOpenFieldObjMenu = (event: any, formField: EditorFieldObject) => {
    // prepare states to render the formFieldMenu with the correct context
    setSelectedFormObj(formField)
    setFormFieldMenuAnchor(event.currentTarget)

    // render formFieldMenu
    setOpenFieldObjMenu(true)
  }

  const handleCloseFieldObjMenu = () => {
    if(selectedFormObj?.label !== '') {
      setOpenFieldObjMenu(false)
    }
  }

  const handleChangeFieldObjInputLength = (event: any) => {
    updateFieldObj(selectedFormObj as EditorFieldObject, 'type', event.target.value)
  }

  const handleChangeFieldObjLabel = (event: any) => {
    updateFieldObj(selectedFormObj as EditorFieldObject, 'label', event.target.value)
  }

  const handleChangeFieldObjIsRequired = (event: any) => {
    updateFieldObj(selectedFormObj as EditorFieldObject, 'required', event.target.checked)
  }
  /* ------------------------- Callback functions end ------------------------- */



  /* ------------------------- Render constants start ------------------------- */
  const renderEditorFieldObject = (fieldObj: EditorFieldObject): React.ReactNode => {
    const fieldType = fieldObj.type
    switch(fieldType) {
      case 'addFormField':
        return (
          <Button
            startIcon={<AddRounded style={{ fontSize: '25px', color: 'GrayText'}} />}
            onClick={handleAddFormField}
            sx={{ textTransform: 'none', fontSize: '16px', color: 'black', ml: '4px' }}
          >
            {t('common:interaction.button.addTextField')}
          </Button>
        )
      case 'text':
        return (
          <Box display='flex' alignItems='center'>
            <Stack
              direction='row'
              sx={{height: '40px', mr: '0.2rem'}} // height needs to be set otherwise the iconbuttons become oval
            >
              <IconButton onClick={() => handleDeleteFormField(fieldObj)}>
                <DeleteRounded/>
              </IconButton>
              <IconButton onClick={(e) => handleOpenFieldObjMenu(e, fieldObj)}>
                <EditRounded/>
              </IconButton>
            </Stack>
            <TextField
              fullWidth
              label={fieldObj.label}
              required={fieldObj.required}
            />
          </Box>
        )
      case 'multiline':
        return (
          <Box display='flex' alignItems='start'>
            <Stack
              direction='row'
              sx={{height: '40px', mr: '0.2rem', mt: '0.5rem'}} // height needs to be set otherwise the icon-buttons become oval
            >
              <IconButton onClick={() => handleDeleteFormField(fieldObj)}>
                <DeleteRounded/>
              </IconButton>
              <IconButton onClick={(e) => handleOpenFieldObjMenu(e, fieldObj)}>
                <EditRounded/>
              </IconButton>
            </Stack>
            <TextField
              fullWidth
              label={fieldObj.label}
              required={fieldObj.required}
              multiline
              rows={4}
            />
          </Box>
        )
    }
  }
  /* -------------------------- Render constants end -------------------------- */



  /* ------------------------ Pre render actions start ------------------------ */

  /* ------------------------- Pre render actions end ------------------------- */

  log.debug("formJson ->", formJson,)

  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */

  return (
    <>
      <MultilevelDrawer
        open={open}
        setOpen={setOpen}
        onClose={handleClose}
        title={t('common:content.heading.createFeedbackForm') as string}
        size="big"
        confirmButtonProps={{
          text: t('common:interaction.button.createFeedbackForm'),
          onConfirm: handleConfirm,
          disabled: formJson.formTitle.trim() === '' || formJson.formFields.length <= 1
        }}
      >
        <Box
          padding='16px;'
          sx={{border: '1px solid lightGrey', borderRadius: '4px'}}
        >
          <TextField
            fullWidth
            size='small'
            label={t('common:content.label.feedbackFormTitle')}
            inputProps={{style: {fontSize: '1.5rem', lineHeight: '1.334rem', fontWeight: 700, textAlign: 'center'}}} // style the input to be the size of an h5 heading
            value={formJson.formTitle}
            onChange={(e) => setFormJson((prev: any) => ({...prev, formTitle: e.target.value}))}
            error={formJson.formTitle === ''}
            sx={{mb: '2.5rem'}}
          />
          <Grid container spacing={2}>
            {formJson.formFields.map((formFieldJson: any, index: number) => {
              return (
                <Grid item xs={12} key={index}>
                  {renderEditorFieldObject(formFieldJson)}
                </Grid>
              )
            })}
          </Grid>
        </Box>
      </MultilevelDrawer>
      <Popover
        anchorEl={formFieldMenuAnchor}
        open={openFieldObjMenu}
        onClose={handleCloseFieldObjMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        elevation={2}
      >
        <Stack
          direction='row'
          spacing={1}
          alignItems='center'
          sx={{padding: '8px'}}
        >
          <FormControl size="small">
            <InputLabel id='select-formField-input-length'>{t('common:content.label.inputLength')}</InputLabel>
            <Select
              label={t('common:content.label.inputLength')}
              labelId='select-formField-input-length'
              sx={{width: '8rem'}}
              value={selectedFormObj?.type ?? 'text'}
              onChange={handleChangeFieldObjInputLength}
            >
              <MenuItem value={'text'}>{t('common:content.label.short')}</MenuItem>
              <MenuItem value={'multiline'}>{t('common:content.label.long')}</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label={t('common:content.label.fieldLabel')}
            value={selectedFormObj?.label ?? ""}
            onChange={handleChangeFieldObjLabel}
            size="small"
            error={selectedFormObj?.label === ''}
            required
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedFormObj?.required ?? false}
                onChange={handleChangeFieldObjIsRequired}
              />
            }
            label={t('common:content.label.requiredField')}
          />
        </Stack>
      </Popover>
    </>
  )
}