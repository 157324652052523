/* -------------------------- Design imports start -------------------------- */
import { toast } from 'react-toastify';
import { Box, Card, CircularProgress, IconButton, Modal, Skeleton, Slider, Stack, Typography } from '@mui/material';
import { ChevronLeftRounded, ChevronRightRounded, CloseRounded, ZoomInRounded, ZoomOutRounded } from '@mui/icons-material';
/* --------------------------- Design imports end --------------------------- */



/* ------------------------ Functional imports start ------------------------ */
import LogTool from '../../logger/logTools'
import { useRef, useState } from 'react'
import { usePdf } from '@mikecousins/react-pdf';
import { FileInfo } from '../../utils/types';
/* ------------------------- Functional import end -------------------------- */


interface Props {
  file: FileInfo
}



/* -------------------------------------------------------------------------- */
/*                               Start component                              */
/* -------------------------------------------------------------------------- */

export default function ImageViewer(props: Props) {
  /* -------------------------- Non state data start -------------------------- */
  const {file} = props
  const log = new LogTool({context: 'ImageViewer', enable: true, logLevel: 'debug'})


  const minScale = 0.1
  const maxScale = 2
  /* --------------------------- Non state data end --------------------------- */




  /* ---------------------------- Flag states start --------------------------- */
  // this allows us to implement drag-scrolling with the mouse
  const [mouseDown, setMouseDown] = useState(false)
  /* ----------------------------- Flag states end ---------------------------- */




  /* --------------------------- Data states start ---------------------------- */
  const [scale, setScale] = useState<number>(1)

  const scrollContainerRef = useRef<HTMLElement | null>(null)
  const imageRef = useRef<HTMLImageElement| null>(null)
  /* ---------------------------- Data states end ----------------------------- */




  /* ------------------------------ Effects start ----------------------------- */

  /* ------------------------------- Effects end ------------------------------ */




  /* ------------------------- Utility functions start ------------------------ */
  /**
   * Takes a value within a 'from-range' and linearly maps it into a 'to-range'. That
   * mapped value is then returned.
   * @param num
   * @param fromRangeMin
   * @param fromRangeMax
   * @param toRangeMin
   * @param toRangeMax
   * @returns
   */
  function mapBetweenRanges(num: number, fromRangeMin: number, fromRangeMax: number, toRangeMin: number, toRangeMax: number) {
    return ((num - fromRangeMin) * (toRangeMax - toRangeMin)) / (fromRangeMax - fromRangeMin) + toRangeMin
  }
  /* -------------------------- Utility functions end ------------------------- */




  /* ------------------------ Callback functions start ------------------------ */
  const handleMouseDrag = (event: any) => {
    if(!mouseDown) return

    if(scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft -= event.movementX
      scrollContainerRef.current.scrollTop -= event.movementY
      log.debug(
        'mouseMovementX ->', event.movementX,
        'mouseMovementY ->', event.movementY,
        'canvas.scrollLeft ->', scrollContainerRef.current?.scrollLeft,
        'canvas.scrollTop ->', scrollContainerRef.current?.scrollTop,
      )
    }

  }



  const handleZoomOut = () => {
    setScale(prev => {
      if(prev <= minScale+0.25) return minScale
      else return prev-=0.25
    })
  }

  const handleZoomIn = () => {
    setScale(prev => {
      if(prev >= maxScale-0.25) return maxScale
      else return prev+=0.25
    })
  }

  const handleZoomSliderChange = (_: any, value: number | number[]) => {
    if(Array.isArray(value)) return
    setScale(mapBetweenRanges(value, 0, 100, minScale, maxScale))
  }
  /* ------------------------- Callback functions end ------------------------- */




  /* ------------------------- Render constants start ------------------------- */

  /* -------------------------- Render constants end -------------------------- */




  /* ------------------------ Pre render actions start ------------------------ */
  // apply the selected scale to the image element
  imageRef.current?.style.setProperty('max-width', `${scale * 100}%`)
  /* ------------------------- Pre render actions end ------------------------- */


  log.debug(
    'file ->', file,
    'scale ->', scale,
  )


  /* -------------------------------------------------------------------------- */
  /*                              Render Component                              */
  /* -------------------------------------------------------------------------- */
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        cursor: mouseDown ? 'grabbing' : 'auto'
      }}
      onMouseMove={handleMouseDrag}
      onMouseUp={() => setMouseDown(false)}
      onMouseLeave={(e: any) => {
        setMouseDown(false)
      }} // disable mouse drag when the cursor leaves the container
      // onWheel={event => {
      //   if(event.ctrlKey) {
      //     // stop whole page from zooming
      //     event.preventDefault()
      //     event.stopPropagation()

      //     if(event.deltaY < 0) handleZoomIn()
      //     if(event.deltaY > 0) handleZoomOut()

      //   }
      // }} //TODO: enable zoom via scrolling implementing: https://stackoverflow.com/questions/56465207/how-can-i-prevent-full-page-zoom-on-mousewheel-event-in-javascript
    >
      <Box
        id='imageContainer'
        height='calc(100% - (2rem + 0.5rem + 40px))' // height of the container minus the height of the pdf controls
        width='100%'
      >
        <Box
          id='scrollContainer'
          ref={scrollContainerRef}
          height='100%'
          width='100%'
          overflow='auto'
        >
          <img
            id='image'
            src={file.document!}
            ref={imageRef}
            onMouseDown={(e: any) => {
              e.preventDefault()
              setMouseDown(true)
            }}
            style={{
              display: 'block',
              maxWidth: '100%',
              maxHeight: 'auto',
              marginLeft: 'auto',
              marginRight: 'auto',
              border: '1px solid lightGray',
              boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.15)',
              cursor: mouseDown ? 'grabbing' : 'grab',
            }}
          />
        </Box>
      </Box>
      <Card
        id='imageControlsContainer'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: '16px',
          p: '4px',
          border: '1px solid lightGray'
        }}
      >
        <Stack
          id='zoomControlsContainer'
          direction='row'
          alignItems='center'
          width={200}
        >
          <IconButton
            onClick={handleZoomOut}
            disabled={scale <= minScale}
          >
            <ZoomOutRounded fontSize='large'/>
          </IconButton>
          <Slider
            value={Math.ceil(mapBetweenRanges(scale, minScale, maxScale, 0, 100))}
            onChange={handleZoomSliderChange}
          />
          <IconButton
            onClick={handleZoomIn}
            disabled={scale >= maxScale}
          >
            <ZoomInRounded fontSize='large'/>
          </IconButton>
        </Stack>
      </Card>
    </div>
  )
}
