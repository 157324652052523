/* -------------------------- Design imports start -------------------------- */
import { Divider, Grid, Link } from '@mui/material'
import { t } from 'i18next'
/* --------------------------- Design imports end --------------------------- */

/* ------------------------ Functional imports start ------------------------ */
import React from 'react'
/* ------------------------- Functional imports end ------------------------- */

/* -------------------------------------------------------------------------- */
/*                               Start component                              */
/* -------------------------------------------------------------------------- */
export default function Footer() {
  /* -------------------------------------------------------------------------- */
  /*                              Render component                              */
  /* -------------------------------------------------------------------------- */
  return(
  <Grid container>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    <Grid item xs={12} style={{ textAlign: 'center', color: 'grey', padding: 8 }}>
      Copyright © {new Date().getFullYear()}
      {' - '}
      <span style={{ fontWeight: 'bold' }}>assemblean platform v0.13.1-Beta </span>
      {' - '}
      <Link href="https://assemblean.com/" target="_blank" rel="noreferrer" color="secondary">
         assemblean
      </Link>
      {' - '}
      <Link
        href="https://assemblean.com/privacy-policy/"
        target="_blank"
        rel="noreferrer"
        color="secondary"
      >
        {t('common:content.label.privacyPolicy')}
      </Link>
    </Grid>
  </Grid>
  )
}
