/* -------------------------- Design imports start -------------------------- */
import { toast } from "react-toastify"
import StepIcon from "@mui/material/StepIcon"
import { Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography
} from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { Form, FormFieldType } from "../../Form"
import { AddRounded, CheckBoxRounded, DeleteRounded, ExpandMoreRounded, TipsAndUpdatesRounded, WarningRounded } from "@mui/icons-material"
import SearchSelectInput from "../../../components/inputs/SearchSelectInput"
import MultilevelDrawer from "../../../components/layout/MultilevelDrawer"
import NumberInputWithUnit from "../../../components/inputs/NumberInputWithUnit"
import FieldLabel from "../../../components/widgets/FieldLabel"
import FormEditorDrawer from "../../FormEditor/components/FormEditorDrawer"
import ContactDrawer from "../../Customer/ContactDrawer"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
/* --------------------------- Design imports end --------------------------- */


/* ------------------------ Functional imports start ------------------------ */
import { Request } from '../utils/types'
import { useTranslation } from "react-i18next"
import LogTool from "../../../logger/logTools"
import { FileInfo, HTTPMethod, RichContact, SelectOption } from "../../../utils/types"
import { Article, FeedbackForm, Sharepoint } from "../../Item/utils/types"
import { useHistory } from "react-router-dom"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { createArticle,
  createFeedbackForm,
  createSharepoint,
  fetchFeedbackForms,
  getArticleRepresentation,
  getFormObjectFromArticle,
  inputToAPIArticleJSON,
  inputToAPIFeedbackFormJSON
} from "../../Item/utils/functions"
import {
  createContact,
  createFileInfo,
  fetchFileList,
  fetchRichContacts,
  formInputToAPIContactJSON,
  getContactRepresentation,
  getLocalFileUrl,
  getPKfromSelf,
  handleAPICallV1
} from "../../../utils/functions"
import { Supplier } from "../../Supplier/utils/types"
import { Customer } from "../../Customer/utils/types"
import Button from "../../../components/Button"
import GetConstant from "../utils/constants"
import FilesTable from "../../../components/widgets/FilesTable"
import ProcessSelection from "../../Item/ManufacturingProcess.tsx/ProcessSelection"
import FileInput from "../../../components/inputs/FileInput"
import Cookies from "js-cookie"
import { generateAddFormFieldButton, generateTextField } from "../../FormEditor/utils/functions"
import InfoField from "../../../components/inputs/InfoField"
import InteractiveTextDisplay from "../../../components/widgets/InteractiveTextDisplay"
/* ------------------------- Functional imports end ------------------------- */


  type Props = {
    open: boolean
    setOpen: (open: boolean) => void
    selectedRequest: Request
    onClose?: () => void
    onConfirm: (input: any) => Promise<Sharepoint | "ERROR">
  }

  type AutomaticShareFormInput = {
    [inputKey: string]: {contact: SelectOption, customInvitationMessage: string}
  }



  /* -------------------------------------------------------------------------- */
  /*                               Start Component                              */
  /* -------------------------------------------------------------------------- */

  export default function CreateRequestSharepointDrawer(props: Props) {
    /* -------------------------- Non state data start -------------------------- */
    const {
      open,
      setOpen,
      selectedRequest,
      onClose = () => null,
      onConfirm,
    } = props
    const log = new LogTool({context: "CreateRequestSharepointDrawer", enable: true, logLevel: 'debug'})
    const { t } = useTranslation()
    const history = useHistory()
    const securePasswordGenerator = require('secure-random-password')


    const defaultShareDuration = "20"

    const customTheme = createTheme({
      components: {
        MuiStepIcon: {
          styleOverrides: {
            root: {
              '&.Mui-active': {
                color: '#16a9e4',
              },
              '&.Mui-completed': {
                color: '#16a9e4',
              },
            },
            text: {
              color: 'white', // Set the text color to white for all steps
            },
          },
        },
      },
    })
    /* --------------------------- Non state data end --------------------------- */



    /* ---------------------------- Flag states start --------------------------- */
    const [waitingOnCreatingSharepoint, setWaitingOnCreatingSharepoint] = useState(false)
    const [waitingOnUploadingFiles, setWaitingOnUploadingFiles] = useState(false)
    const [isPurchasedPart, setIsPurchasedPart] = useState(false)
    const [isManufacturedPart, setIsManufacturedPart] = useState(false)
    const [savedAnonymizedFiles, setSavedAnonymizedFiles] = useState(false)
    // this form-state is purely for frontend purposes. In the backend unlimited
    // sharepoint duration is set by setting the sharepoint duration to 0
    const [unlimitedShareDuration, setUnlimitedShareDuration] = useState(false)
    const [openFormEditorDrawer, setOpenFormEditorDrawer] = useState(false)
    const [openContactDrawer, setOpenContactDrawer] = useState(false)
    const [openArticleDrawer, setOpenArticleDrawer] = useState(false)
    const [openFilesDrawer, setOpenFilesDrawer] = useState(false)
    /* ----------------------------- Flag states end ---------------------------- */



    /* ---------------------------- Data states start --------------------------- */
    const uniqueKeyStore = useRef(0)
    const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null)
    const [activeStep, setActiveStep] = useState<number>(0)
    const [articleFormState, setArticleFormState] = useState<any>({})
    const [processSelectionFormState, setProcessSelectionFormState] = useState<any>({})
    const [additionalFiles, setAdditionalFiles] = useState<FileInfo[]>([])
    const [sharepointFormState, setSharepointFormState] = useState<any>({})
    const [manualSharesForm, setManualSharesForm] = useState<any>([{type: 'addManualShare'}])
    const [manualSharesFormInput, setManualSharesFormInput] = useState<{
      [inputKey: string]: {comment: string, password?: string, accessUrl?: string}
    }>({})
    const [automaticSharesForm, setAutomaticSharesForm] = useState<any>([{type: 'addAutomaticShare'}])
    const [automaticSharesFormInput, setAutomaticSharesFormInput] = useState<AutomaticShareFormInput>({})
    const feedbackFormStore = useRef<FeedbackForm[]>([])
    // const [formEditorInput, setFormEditorInput] = useState<any | null>(null)
    const [feedbackFormOptions, setFeedbackFormOptions] = useState<SelectOption[]>([])
    const [feedbackFormPreviewInput, setFeedbackFormPreviewInput] = useState<any>()
    const contactStore = useRef<{[relatedToName: string]: RichContact[]}>({})
    const [contactFormInput, setContactFormInput] = useState<any>({})
    const [contactOptions, setContactOptions] = useState<SelectOption[]>([])
    const [newContactReceiver, setNewContactReceiver] = useState<string>()
    /* ----------------------------- Data states end ---------------------------- */



    /* ------------------------------ Effects start ----------------------------- */
    useEffect(() => {
      fetchFeedbackForms({
        onSuccess: (forms: FeedbackForm[]) => {
          feedbackFormStore.current = [...feedbackFormStore.current, ...forms]
          setFeedbackFormOptions(feedbackFormStore.current.map(
            form => ({value: form.self, label: form.formTitle})
          ))
        }
      })
      fetchRichContacts({
        onSuccess: (richContacts: RichContact[]) => {
          // collect contacts ordered by relation to suppliers / customers / unrelated
          richContacts.forEach((contact: RichContact) => {
            contact.suppliers.forEach((supplier: Supplier) => {
              if(contactStore.current[supplier.name]) {
                contactStore.current[supplier.name].push(contact)
              } {
                contactStore.current[supplier.name] = [contact]
              }
            })
            contact.customers.forEach((customer: Customer) => {
              if(contactStore.current[customer.name]) {
                contactStore.current[customer.name].push(contact)
              } else {
                contactStore.current[customer.name] = [contact]
              }
            })
            if(contact.customers.length === 0 && contact.suppliers.length === 0) {
              if(contactStore.current['unrelated']) {
                contactStore.current['unrelated'].push(contact)
              } else {
                contactStore.current['unrelated'] = [contact]
              }
            }
          })

          // generate list of contact options sorted by suppliers and customers
          let initialContactOptions: SelectOption[] = []
          Object.keys(contactStore.current)
            .forEach((relatedToName: string) => {contactStore.current[relatedToName]
              .forEach((contact: RichContact) => {
                initialContactOptions.push({
                  value: contact.self,
                  label: getContactRepresentation(
                    contact,
                    relatedToName !== 'unrelated' ? relatedToName : ''
                  )
                })
              })
            })

          // update state
          setContactOptions(initialContactOptions)
        }
      })
    }, [])

    // useEffect(() => {
    //   const unlistedFiles = additionalFiles.filter(file => {
    //     // return true if the file is not yet present in articleFormState.files
    //     for(let listedFile of articleFormState.files) {
    //       return file.name! !== listedFile.name!
    //     }
    //   })

    //   if(additionalFiles.length > 0 && unlistedFiles.length > 0) {
    //     setArticleFormState((prev: any) => {
    //       return {
    //         ...prev,
    //         files: [
    //           ...prev.files,
    //           ...unlistedFiles.map(file => {
    //             const fileUrl = getLocalFileUrl(file)
    //             return {
    //               ...file,
    //               document: fileUrl,
    //             }
    //           })
    //         ]
    //       }
    //     })
    //   }
    // }, [additionalFiles])
    /* ------------------------------- Effects end ------------------------------ */




    /* ------------------------- Utility functions start ------------------------ */
    /**
     * Generates a unique element key (ascending number).
     * @returns
     */
    function getUniqueKey(): string {
      const key = uniqueKeyStore.current
      uniqueKeyStore.current += 1
      return String(key)
    }

    /**
     * Check if either a value or unit is specified for a dimension FormField. If so, the according unit / value is required!
     * @param dimension Key of the dimension FormField.
     * @returns
     */
    const isRequiredDimension = (dimension: string): boolean => {
      return (
        Object.hasOwn(articleFormState, dimension) &&
        (articleFormState[dimension] !== undefined && articleFormState[dimension] !== '') &&
        ((articleFormState[dimension].value && !articleFormState[dimension].unit) ||
          (!articleFormState[dimension].value && articleFormState[dimension].unit))
      )
    }


    function getBaseAnonymizedArticle(requestArticle: Article) {
      return {
        ...getFormObjectFromArticle(requestArticle),
        number: requestArticle.number + ' (anonymized)',
        variationOf: {
          value: requestArticle.self,
          label: getArticleRepresentation(requestArticle)
        },
        sharedRequest: selectedRequest.self,
        // remove files and wait for them to be populated after the anonymized request article has been saved
        files: '',
      }
    }

    /**
     * Creates a new, unique ManualShareFieldObject that can be inserted into the manualSharesForm.
     * @returns
     */
    function generateManualShareFieldObject() {
      const fieldObjKey = `manualShare${getUniqueKey()}`
      return {
        key: fieldObjKey,
        label: t("common:content.label.comment"),
        onChange: (input: string) => {
          log.debug('Updating', fieldObjKey, 'with value ->', input)
          setManualSharesFormInput((prev: any) => {
            if(prev[fieldObjKey]?.comment) {
              // the user entered a comment before already -> update it
              prev[fieldObjKey] = {...prev[fieldObjKey], comment: input}
            } else {
              prev[fieldObjKey] = {
                comment: input,
                password: articleFormState.password ?? securePasswordGenerator.randomPassword()
              }
            }
            return {...prev}
          })
        }
      }
    }
    /**
     * Creates a new, unique AutomaticShareFieldObject that can be inserted into the automaticSharesForm.
     * @returns
     */
    function generateAutomaticShareFieldObject() {
      const fieldObjKey = `automaticShare${getUniqueKey()}`
      return {
        key: fieldObjKey,
        label: t("common:content.label.comment"),
      }
    }

    /**
     * Determines if there are any empty automatic share comment input fields.
     * @returns
     */
    function missingAutomaticShareSelect(): boolean {
      let selectIsMissing = false
      const automaticSharesInputValues: SelectOption[] = Object.values(automaticSharesFormInput)
                                                                  .map(
                                                                    (autoShare: {contact: SelectOption, customInvitationMessage: string}) => autoShare.contact
                                                                  )
      if( (automaticSharesForm.length - 1) > automaticSharesInputValues.length ) {
        // there is a automaticShareFieldObject that was not yet used to select a automatic share contact
        selectIsMissing = true
      }
      if(automaticSharesInputValues.length > 0) {
        for(const automaticShare of automaticSharesInputValues) {
          if(!automaticShare) {
            selectIsMissing = true;
            break;
          }
        }
      }
      return selectIsMissing
    }
    /**
     * Determines if there are any empty manual share comment input fields.
     * @returns
     */
    function missingManualShareComment(): boolean {
      let commentIsMissing = false
      const manualSharesFormInputValues: {comment: string, password?: string}[] = Object.values(manualSharesFormInput)
      if( (manualSharesForm.length - 1) > manualSharesFormInputValues.length ) {
        // there is a manualSharesFormFieldObject that was not yet used to enter a manual share comment
        commentIsMissing = true
      }
      if(manualSharesFormInputValues.length > 0) {
        for(const manualShare of manualSharesFormInputValues) {
          if(manualShare.comment === '') {
            commentIsMissing = true;
            break;
          }
        }
      }
      return commentIsMissing
    }
    /* -------------------------- Utility functions end ------------------------- */



    /* ------------------------ Callback functions start ------------------------ */
    const handleClose = (preventOnClose: boolean = false) => {
      // reset drawer to initial state
      uniqueKeyStore.current = 0
      setActiveStep(0)
      setManualSharesForm([{type: 'addManualShare'}])
      setManualSharesFormInput({})
      setAutomaticSharesForm([{type: 'addAutomaticShare'}])
      setAutomaticSharesFormInput({})
      setFeedbackFormPreviewInput({})
      setArticleFormState({})

      setWaitingOnCreatingSharepoint(false)
      setUnlimitedShareDuration(false)

      setOpen(false)


      !preventOnClose && onClose()
    }

    const handleBack = () => {
      if(activeStep > 0) {
        setActiveStep(prev => prev - 1)
      }
    }

    const handleNext = () => {
      if(activeStep < steps.length) {
        setActiveStep(prev => prev + 1)
      }
    }

    const handleCreateRequestSharepoint = async () => {
      // visual feedback
      setWaitingOnCreatingSharepoint(true)

      // posting request to server and waiting on response
      const confirmStatus = await onConfirm({
        ...sharepointFormState,
        shareDuration: unlimitedShareDuration ? "0" : (articleFormState.shareDuration ?? defaultShareDuration),
        articles: [articleFormState.self],
        request: selectedRequest.self,
        ...(sharepointFormState.feedbackForm && {feedbackForm: sharepointFormState.feedbackForm.value}),
        ...(sharepointFormState.automaticShares && {automaticShares: sharepointFormState.automaticShares.map(
          (supplier: SelectOption) => supplier.value)}
        ),
        ...((automaticSharesForm.length > 1 && Object.values(automaticSharesFormInput).length > 0) && {
          // convert the formInput object into a list of automatic share info-objects
          automaticShares: Object.values(automaticSharesFormInput)
                            .map(automaticShare => (
                              {
                                contact: automaticShare.contact.value,
                                customInvitationMessage: automaticShare.customInvitationMessage
                              }
                            ))}
        ),
        ...((manualSharesForm.length > 1 && Object.values(manualSharesFormInput).length > 0) && {
          // convert the formInput object into a list of manual share info-objects
          manualShares: Object.values(manualSharesFormInput)}
        ),
      })

      if(typeof confirmStatus === 'object') {
        toast.success(t("sharepoint:feedback.success.creatingSharepoint"))
        const sharepoint: Sharepoint = createSharepoint(confirmStatus)
        // go to next step (does not really exist) to display the generated sharepoint url
        const [response, error] = await handleAPICallV1(
          HTTPMethod.GET,
          sharepoint!.self + 'manual_shares/',
          undefined,
          undefined,
        )

        if(!error && response) {
          log.info('Success fetching manual shares (GuestUsers)', response.results)
          // combine all manual shares data from user input and server response
          setManualSharesFormInput(
            response.results.map((manualShareServerResp: any) => {
              // compare manual share from server response with the manual shares created within this drawer
              const match: any = Object.values(manualSharesFormInput).find(
                (manualShareInput: any) => {
                  log.debug('Compare server resp against', manualShareInput)
                  return manualShareInput.comment === manualShareServerResp.comment
                }
              )
              return {
                comment: match?.comment,
                password: match?.password,
                accessUrl: window.location.origin + '/guest-signIn/' + manualShareServerResp.guest_id
              }
            })
          )
        }
        if(Object.keys(manualSharesFormInput).length > 0) {
          handleNext()
        } else {
          handleClose(true)
        }
      } else {
        toast.error(t('sharepoint:feedback.error.creatingSharepoint'))
      }
      setWaitingOnCreatingSharepoint(false)
    }

    const handleSaveAnonymizedRequestArticle = async () => {
      if(typeof selectedRequest.article === 'string') return

      if(!articleFormState.self
         || articleFormState.self === selectedRequest.article.self)
      {
        // the anonymizedRequestArticle has the same id as the requestArticle
        // or no id at all -> anonymizedRequestArticle has not been saved/created yet
        log.info('Begin sending anonymized request article')

        const anonymizedRequestArticle: any = articleFormState
        if(typeof anonymizedRequestArticle.self !== 'undefined') {
          delete anonymizedRequestArticle.self
        }
        if(isManufacturedPart) {
          // add the selected process to the anonymized article
          anonymizedRequestArticle.manufacturingProcess = processSelectionFormState

          // reset purchasedPartFormFields
          anonymizedRequestArticle.supplier = undefined
          anonymizedRequestArticle.countryOfOrigin = ''
          anonymizedRequestArticle.batchSize = 0
          anonymizedRequestArticle.minimumOrder = undefined
          anonymizedRequestArticle.leadTime = undefined
        }

        anonymizedRequestArticle.weightUnit = 'kg'
        anonymizedRequestArticle.volumeUnit = 'cubic_meter'
        anonymizedRequestArticle.lengthUnit = 'm'
        anonymizedRequestArticle.widthUnit = 'm'
        anonymizedRequestArticle.heightUnit = 'm'

        const [response, error] = await handleAPICallV1(
          HTTPMethod.POST,
          'items/articles/',
          undefined,
          inputToAPIArticleJSON(anonymizedRequestArticle),
        )

        if(!error && response) {
          log.info('Success creating anonymized request article')

          if(selectedRequest.article.files.length > 0) {
            // the request article holds files that are / will be copied to
            // the anonymized request article. Since we do not know how long that
            // copying process will take we have to poll the files

            pollingIntervalRef.current = setInterval(() => {
              fetchFileList({
                url: response.files,
                onSuccess: (files: FileInfo[]) => {
                  setArticleFormState((prev: any) => {
                    return {
                      ...prev,
                      files: Array.isArray(prev.files)
                        ? [...prev.files, ...files] // add fetched files to already stored files
                        : files
                    }
                  })
                },
                onError: (error: any) => {
                  log.error("Error fetching files for anonymized request article", error)
                  toast.error(t('common:feedback.errorFetchingFiles'))
                }
              })
            }, 1200) // poll every 1.2 seconds
          } else {
            // the request article holds no files and therefore the anonymized request
            // article does neither
            response.files = []
          }


          // set articleFormState to the received data until the fetched data is available
          // -> this enables us to display a loading state for the FilesTable
          const anonymizedArticle: Article = createArticle(response)
          setArticleFormState((prev: any) => {
            return {
              ...prev,
              self: anonymizedArticle.self, // update self to note that the anonymized request article was saved
              key: getPKfromSelf(anonymizedArticle.self)
            }
          })
        } else {
          log.error('Error creating anonymized request article', error)
          toast.error(t('item:feedback.error.creatingArticle'))
        }
      } else {
        // the anonymized request article has its own id and therefore has
        // been saved before / has its own instance in the backend database
        // -> update the anonymized request article
        log.info('Begin updating anonymized request article')
        const [response, error] = await handleAPICallV1(
          HTTPMethod.PUT,
          articleFormState.self,
          undefined,
          inputToAPIArticleJSON(articleFormState)
        )

        if(!error && response) {
          log.info('Success updating anonymized request article')
          toast.success('item:feedback.success.updatingArticle')

          // fetchArticle({
          //   url: response.self,
          //   onSuccess: (updatedArticle: Article) => {
          //     setArticleFormState(getFormObjectFromArticle(updatedArticle))
          //   },
          //   onError: (error: any) => {
          //     log.error('Error fetching updated anonymized request article', error)
          //     toast.error(t('item:feedback.error.fetchingA'))
          //   }
          // })

        } else {
          log.error('Error updating anonymized request article', error)
          toast.error(t('item:feedback.error.updatingArticle'))
        }
      }
    }

    const handleSaveAnonymizedArticleFiles = async () => {
      // visual feedback
      setWaitingOnUploadingFiles(true)

      // delete files
      const deletePromises = articleFormState.files
        .filter((file: FileInfo) => file.markedAsDeleted && typeof file.self === 'string')
        .map((file: FileInfo) => handleAPICallV1(
          HTTPMethod.DELETE,
          file.self!,
          undefined,
          undefined,
          'text',
        ))
      const deleteResults = await Promise.allSettled(deletePromises)

      // create files
      const createPromises = additionalFiles.map(file => {
        const fileFormData = new FormData()
        fileFormData.append('document', file as File)

        return handleAPICallV1(
          HTTPMethod.POST,
          articleFormState.self + 'files/',
          {
            Accept: '*/*',
            Authorization: `Bearer ${Cookies.get('access')}`,
          },
          fileFormData,
          'text',
          true,
        )
      })
      const createResults = await Promise.allSettled(createPromises)


      // visual feedback
      const results = [...deleteResults, ...createResults]
      if(results.length > 0) {
        if(results.every(result => result.status === 'rejected')) {
          toast.error(t('files:feedback.error.savingFileChanges'))
        } else if(results.some(result => result.status === 'rejected')) {
          toast.warn(t('files:feedback.warn.savingFileChanges'))
        } else {
          toast.success(t('files:feedback.success.savingFileChanges'))
          setSavedAnonymizedFiles(true)
        }

        // update files in table
        fetchFileList({
          url: articleFormState.self + 'files/',
          onSuccess: (files: FileInfo[]) => {
            // remove all files that were created successfully from the additionalFiles list
            const updatedAdditionalFiles = additionalFiles.filter(additionalFile => {
              for(let createdFile of files) {
                if(additionalFile.name === createdFile.name
                   || additionalFile.name?.replaceAll(' ', '_') === createdFile.name
                  ) {
                  // found a created file with the same name as from the additional file
                  return false
                }
              }
              // non of the created files matches the additional file -> keep it in the list
              return true
            })
            setAdditionalFiles(updatedAdditionalFiles)
            setArticleFormState((prev: any) => {
              // TODO: handle pagination
              return {
                ...prev,
                files: files,
              }
            })
          },
          onError: (error: any) => {
            log.error('Error updating files in table', error)
            toast.error(t('files:feedback.error.updatingFilesInTable'))
          },
          onFinal: () => {
            setWaitingOnUploadingFiles(false)
          }
        })
      } else {
        // nothing happened, no need to re-fetch files
        toast.success(t('files:feedback.success.savingFileChanges'))
        setSavedAnonymizedFiles(true)
        setWaitingOnUploadingFiles(false)
      }
    }

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsPurchasedPart(event.target.checked)
      setIsManufacturedPart(false)
    }

    const handleManufactureCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsManufacturedPart(event.target.checked)
      setIsPurchasedPart(false)
    }

    const handleAddFormOption = async (feedbackForm: any): Promise<"SUCCESS" | "ERROR"> => {
      log.info("Begin sending feedback form.")
      const [response, error] = await handleAPICallV1(
        HTTPMethod.POST,
        'items/feedback_forms/',
        undefined,
        inputToAPIFeedbackFormJSON(feedbackForm)
      )

      if(response && !error) {
        log.info("Success creating feedback form")
        const feedbackForm: FeedbackForm = createFeedbackForm(response)

        setFeedbackFormOptions(prev => [
          ...prev,
          { value: feedbackForm.self, label: feedbackForm.formTitle}
        ])

        //set selected contact option
        setSharepointFormState((prev: any) => {return {
          ...prev,
          feedbackForm: {value: feedbackForm.self, label: feedbackForm.formTitle}
        }})

        // add feedback form to formStore
        feedbackFormStore.current = [...feedbackFormStore.current, feedbackForm]

        return 'SUCCESS'
      } else {
        log.error('Error while creating feedback form option', error)
        return 'ERROR'
      }
    }

    const handleAddAutomaticShare = () => {
      setAutomaticSharesForm((prev: any) => {
        // we need to store this index to enable the onChange method
        const fieldObjIndex: number = prev.length - 1

        // replace the last element with a new textfield
        prev[fieldObjIndex] = generateAutomaticShareFieldObject()

        // add 'addFormField' entry to the end of formJson
        return [...prev, {type: 'addAutomaticShare'}]
      })

    }

    const handleAddManualShare = () => {
      setManualSharesForm((prev: any) => {
        // we need to store this index to enable the onChange method
        const fieldObjIndex: number = prev.length - 1

        // replace the last element with a new textfield
        prev[fieldObjIndex] = generateManualShareFieldObject()

        // add 'addFormField' entry to the end of formJson
        return [...prev, {type: 'addManualShare'}]
      })
    }

    const handleDeleteAutomaticShare = (automaticShareFieldObj: any) => {
      setAutomaticSharesForm((prev: any) => {
        const i = prev.indexOf(automaticShareFieldObj)
        // remove manualShareFieldObject
        prev.splice(i, 1)

        // delete the according manualShareFormInput
        setAutomaticSharesFormInput((prev: any) => {
          delete prev[automaticShareFieldObj.key]
          return {...prev}
        })
        return [...prev]
      })
    }

    const handleDeleteManualShare = (manualShareFieldObj: any) => {
      setManualSharesForm((prev: any) => {
        const i = prev.indexOf(manualShareFieldObj)
        // remove manualShareFieldObject
        prev.splice(i, 1)

        // delete the according manualShareFormInput
        setManualSharesFormInput((prev: any) => {
          delete prev[manualShareFieldObj.key]
          return {...prev}
        })
        return [...prev]
      })
    }

    const handleAddContactOption = async (contactFormInput: any): Promise<"SUCCESS" | "ERROR"> => {
      log.info("Begin sending contact.")
      const json = formInputToAPIContactJSON(contactFormInput)
      log.debug("Input ->", contactFormInput, ", JSON ->", json)

      // create new contact
      const [response, error] = await handleAPICallV1(
        HTTPMethod.POST,
        'contacts/contacts/',
        undefined,
        json
      )

      if(!error && response) {

        // update contact options
        const newContact = createContact(response)
        if(contactStore.current['unrelated']) {
          contactStore.current['unrelated'].push(newContact as RichContact)
        } else {
          contactStore.current['unrelated'] = [newContact as RichContact]
        }
        setContactOptions(prev => [
            ...prev,
            {
              value: newContact.self,
              label: getContactRepresentation(newContact),
            }
          ]
        )

        // update the select that was used to initiate the contact creation process
        setAutomaticSharesFormInput(prev => {
          prev[newContactReceiver as string] = {
            contact: {
              value: newContact.self,
              label: getContactRepresentation(newContact)
            },
            customInvitationMessage: ''
          }
          return {...prev}
        })

        return "SUCCESS"
      } else {
        log.error("Error creating contact option", error)
        return "ERROR"
      }
    }
    /* ------------------------- Callback functions end ------------------------- */



    /* ------------------------- Render constants start ------------------------- */
    // FormFields
    const requestSharepointArticleFormFields: FormFieldType[] = GetConstant({
      name: 'requestSharepointArticleFields',
    }) as FormFieldType[]
    const dimensionsFormFields: FormFieldType[] = GetConstant({
      name: 'dimensionsFormFields',
      isRequiredDimension: isRequiredDimension,
    }) as FormFieldType[]
    const purchasedPartFormFields: FormFieldType[] = GetConstant({
      name: 'purchasedPartFormFields'
    }) as FormFieldType[]

    // derived state
    let savedAnonymizedArticle: boolean = false
    let filePollingCompleted: boolean = false

    const CustomStepIcon = (props: any) => {
      return (
        <ThemeProvider theme={customTheme}>
          <StepIcon {...props} />
        </ThemeProvider>
      )
    }

    const steps = [
      t('common:content.label.hints'),
      t('item:content.label.anonymizeArticle'),
      t('sharepoint:content.label.configureSharepoint'),
      t('sharepoint:content.label.shareSharepoint'),
    ]

    const implicationsAndComments = [
      {
        content: <span><b>{t('sharepoint:content.description.requestSharepointHints.anonymizeArticleInformationHeading')}</b><br/>
                 {t('sharepoint:content.description.requestSharepointHints.anonymizeArticleInformationHint')}</span>,
        icon: <WarningRounded color='warning'/>
      },
      {
        content: <>
          <span>{t('sharepoint:content.description.requestSharepointHints.editSharepointHint')}</span><br/>
          <Button
            variant='contained'
            color='inherit'
            sx={{ mt: '0.5rem'}}
            onClick={() => {
              // since switching to another page closes the drawer without calling the onClose
              // callbacks we have to cleanup manually
              document.getElementById('root')!.style.overflow = 'unset'
              history.push('/items/sharepoints', {openTab: 'sharedRequestsOverview', selectedRequest: selectedRequest})
            }}
          >
            {t('sharepoint:interaction.button.editSharepoint')}
          </Button>
        </>,
      },
    ]

    const renderDrawerHeader = () => {
      return (
        <Box display='flex' width='100%'>
          <Typography variant="h6" noWrap sx={{ flexShrink: 0, mr: '2rem' }}>
            {t('sharepoint:content.heading.requestQuotes')}
          </Typography>
          <Stepper
            activeStep={activeStep}
            sx={{ marginRight: '16px', marginLeft: '-8px', width: '100%' }}
          >
            {steps.map((label: string, index: number) => {
              return (
                <Step key={index}>
                  <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
                </Step>
              )
            })}
          </Stepper>
        </Box>
      )
    }

    const renderDrawerActions = () => {
      return (
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
          {/* ----------------------- Start cancel / back buttons ---------------------- */}
          {activeStep === 0 &&
            // show cancel-button on first step and after creating the sharepoint
            <Button
              id="cancelButton"
              onClick={() => setOpen(false)}
              color="inherit"
              variant="contained"
              disabled={waitingOnCreatingSharepoint}
              sx={{ mr: 1}}
            >
              {t('common:interaction.button.cancel')}
            </Button>
          }
          {(activeStep > 0 && activeStep < steps.length) &&
            <Button
              id="backButton"
              color="inherit"
              variant="contained"
              disabled={waitingOnCreatingSharepoint}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              {t('common:interaction.button.back')}
            </Button>
          }
          {/* ------------------------ End cancel / back buttons ----------------------- */}

          {/* ---------------------- Start next / confirm buttons ---------------------- */}
          {activeStep < 3 &&
            <Button
              id="nextButton"
              variant="contained"
              disabled={
                activeStep === 1 && (
                    !savedAnonymizedArticle
                    || !savedAnonymizedFiles
                  )
                }
              sx={{ marginLeft: '10px'}}
              onClick={handleNext}
            >
              {t('common:interaction.button.next')}
            </Button>
          }
          {activeStep === 3 &&
            <LoadingButton
              id="createButton"
              variant="contained"
              sx={{ textTransform: 'none', marginLeft: '10px', color: 'whitesmoke' }}
              // disabled={nextStepDisabled(activeStep)}
              loading={waitingOnCreatingSharepoint}
              onClick={handleCreateRequestSharepoint}
              disabled={
                // to create a sharepoint the user has to specify at least one sharepoint guest
                (Object.values(automaticSharesFormInput).length === 0 && Object.values(manualSharesFormInput).length === 0)
                || missingAutomaticShareSelect()
                || missingManualShareComment()
              }
            >
              {t('sharepoint:interaction.button.createSharepoint')}
            </LoadingButton>
          }
          {activeStep === steps.length &&
            <Button
              id='doneButton'
              variant='contained'
              sx={{textTransform: 'none', marginLeft: '10px', color: 'whitesmoke'}}
              onClick={() => handleClose(false)}
            >
              {t('common:interaction.button.done')}
            </Button>
          }
          {/* ----------------------- End next / confirm buttons ----------------------- */}
        </Box>
      )
    }

    const renderFeedbackForm = (form: FeedbackForm | undefined) => {
      if(typeof form === 'undefined') {
        return (
          <Typography variant="body1" color='GrayText' textAlign='center' width='100%' mt='1.5rem' mb='1.5rem'>
            {t('sharepoint:feedback.info.selectFeedbackForm')}
          </Typography>
        )
      }
      return (
        <Grid
          container
          spacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="h5" textAlign='center' fontWeight={700} mb='2.5rem'>
              {form.formTitle}
            </Typography>
          </Grid>
          <Form
            formObject={feedbackFormPreviewInput}
            formFields={form.formJson as FormFieldType[]}
            onChange={(input: any) => setFeedbackFormPreviewInput({...input})}
            editing
          />
        </Grid>
      )
    }

    const renderAutomaticShareFieldObject = (automaticShareFieldObj: any, listItemKey: number) => {
      if(automaticShareFieldObj.type === 'addAutomaticShare') {
        return (
          <ListItem key={listItemKey}>
            <ListItemIcon>
              <Button
                  startIcon={<AddRounded style={{ fontSize: '25px', color: 'GrayText'}} />}
                  onClick={handleAddAutomaticShare}
                  sx={{ fontSize: '16px', color: 'black', ml: '4px' }}
                  style={{color: 'black', textTransform: 'none'}}
                >
                  {t('sharepoint:content.label.addAutomaticShare')}
                </Button>
            </ListItemIcon>
          </ListItem>
        )
      }
      return (
        <ListItem key={listItemKey} sx={{alignItems: 'start'}}>
          <ListItemIcon sx={{marginTop: '0.6875rem'}}>
            <IconButton onClick={() => handleDeleteAutomaticShare(automaticShareFieldObj)}>
              <DeleteRounded/>
            </IconButton>
          </ListItemIcon>
          <ListItemText>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <SearchSelectInput
                  name={t('sharepoint:content.label.shareWith')}
                  fullWidth
                  value={automaticSharesFormInput[automaticShareFieldObj.key]?.contact ?? undefined}
                  // exclude all contacts that are selected already
                  options={contactOptions.filter(
                    (option: SelectOption) => !Object.values(automaticSharesFormInput)
                                                      .map((autoShare: {contact: SelectOption, customInvitationMessage: string}) => autoShare.contact)
                                                      .includes(option))
                  }
                  onChange={(input: any) => {
                    const fieldObjKey = automaticShareFieldObj.key
                    log.debug('Updating', fieldObjKey, 'with value ->', input)
                    setAutomaticSharesFormInput(prev => {
                      if(!prev[fieldObjKey]) {
                        // first select change -> create input object
                        prev[fieldObjKey] = {contact: input, customInvitationMessage: ''}
                      } else {
                        prev[fieldObjKey] = {...prev[fieldObjKey], contact: input}}
                      return {...prev}
                    })
                  }}
                  onAddOption={() => {
                    setNewContactReceiver(automaticShareFieldObj.key)
                    setOpenContactDrawer(true)
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={
                    (automaticSharesFormInput[automaticShareFieldObj.key]?.customInvitationMessage ?? '').length > 0
                      ? 'Personalisierte Einladungsnachricht' + `    ${(automaticSharesFormInput[automaticShareFieldObj.key]?.customInvitationMessage ?? '').length}/500`
                      : 'Personalisierte Einladungsnachricht'
                  }
                  value={automaticSharesFormInput[automaticShareFieldObj.key]?.customInvitationMessage ?? ''}
                  disabled={!automaticSharesFormInput[automaticShareFieldObj.key]?.contact}
                  fullWidth
                  multiline
                  rows={2}
                  error={(automaticSharesFormInput[automaticShareFieldObj.key]?.customInvitationMessage ?? '').length > 500}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    const fieldObjKey = automaticShareFieldObj.key
                    const input = event.target.value
                    log.debug('Updating', fieldObjKey, 'with value ->', input)

                    setAutomaticSharesFormInput(prev => {
                      prev[fieldObjKey] = {...prev[fieldObjKey], customInvitationMessage: input}
                      return {...prev}
                    })
                  }}
                />
              </Grid>
            </Grid>
          </ListItemText>
        </ListItem>
      )
    }

    const renderManualShareFieldObject = (manualShareFieldObj: any, listItemKey: number) => {
      if(manualShareFieldObj.type === 'addManualShare') {
        return (
          <ListItem key={listItemKey}>
            <ListItemIcon>
              <Button
                  startIcon={<AddRounded style={{ fontSize: '25px', color: 'GrayText'}} />}
                  onClick={handleAddManualShare}
                  sx={{ textTransform: 'none', fontSize: '16px', color: 'black', ml: '4px' }}
                  style={{color: 'black', textTransform: 'none'}}
                >
                  {t('sharepoint:content.label.addManualShare')}
                </Button>
            </ListItemIcon>
          </ListItem>
        )
      }
      return (
        <ListItem key={listItemKey}>
          <ListItemIcon>
            <IconButton onClick={() => handleDeleteManualShare(manualShareFieldObj)}>
              <DeleteRounded/>
            </IconButton>
          </ListItemIcon>
          <ListItemText>
            <TextField
              value={manualSharesFormInput[manualShareFieldObj.key]?.comment ?? ''}
              onChange={(event: any) => manualShareFieldObj.onChange(event.target.value)}
              autoFocus
              fullWidth
              label={manualShareFieldObj.label}
              required={true}
            />
          </ListItemText>
        </ListItem>
      )
    }
    /* -------------------------- Render constants end -------------------------- */



    /* ------------------------ Pre render actions start ------------------------ */
    if(selectedRequest) {
      // make sure that the request article is defined / expanded
      if(typeof selectedRequest.article === 'string') {
        throw new Error(
          `Request ${selectedRequest.key} does not provide its article, but the article `
          + 'of a request is required. Make sure to expand the "article" property when '
          + 'fetching the requests.'
        )
      }

      if(!Object.hasOwn(articleFormState, 'number')) {
        // articleFormState has not been initialized yet -> initialize it!
        const requestArticle: Article = selectedRequest.article
        setArticleFormState(getBaseAnonymizedArticle(requestArticle))
        setIsManufacturedPart(Boolean(requestArticle.manufacturingProcess) && Object.keys(requestArticle.manufacturingProcess!).length > 0)
        setIsPurchasedPart(Boolean(requestArticle.supplier))
      } else {
        // articleFormState has been initialized and perhaps edited and saved
        if(Object.hasOwn(articleFormState, 'self')
          && articleFormState.self !== selectedRequest.article.self)
        {
          // since the id of the article stored in the articleFormState and the id
          // of the request article are not the same, the request sharepoint
          // article has been created -> enable editing of the files
          savedAnonymizedArticle = true
        }
      }

      filePollingCompleted = (Array.isArray(articleFormState.files)
                              && articleFormState.files.length >= selectedRequest.article.files.length)
      if(pollingIntervalRef.current !== null) {
        // the component is currently polling the anonymized request article files
        // -> check if all files have been fetched and we can stop polling
        if(filePollingCompleted) {
          clearInterval(pollingIntervalRef.current)
          pollingIntervalRef.current = null
        }
      }

      // add additional files to articleFormState
      if(Array.isArray(additionalFiles) && additionalFiles.length > 0
         && Array.isArray(articleFormState.files))
      {
        log.debug('Collecting missing additional files ...')
        const missingAdditionalFiles: FileInfo[] = []
        additionalFiles.forEach(additionalFile => {
          const notAddedYet = articleFormState.files.findIndex(
            (file: FileInfo) => file.name === additionalFile.name
          ) === -1
          if(notAddedYet) {
            missingAdditionalFiles.push({
              ...createFileInfo(additionalFile),
              // TODO: fix getLocalFileUrl
              // document: getLocalFileUrl(additionalFile)
            })
          }
        })

        log.debug('missingAdditionalFiles ->', missingAdditionalFiles)
        // add all missing additional files to articleFormState
        if(missingAdditionalFiles.length > 0) {
          setArticleFormState((prev: any) => {
            prev.files = [...prev.files, ...missingAdditionalFiles]
            return {...prev}
          })
        }
      }
    }
    /* ------------------------- Pre render actions end ------------------------- */

    log.debug(
      "articleFormState ->", articleFormState,
      ', selectedRequest ->', selectedRequest,
      ', additionalFiles ->', additionalFiles,
      ", feedbackFormStore ->", feedbackFormStore.current,
      ", feedbackFormOptions ->", feedbackFormOptions,
      ", contactStore ->", contactStore.current,
      ", contactOptions ->", contactOptions,
      ", automaticSharesForm ->", automaticSharesForm,
      ", automaticSharesFormInput ->", automaticSharesFormInput,
      ", manualSharesForm ->", manualSharesForm,
      ", manualSharesFormInput ->", manualSharesFormInput,
    )

    /* -------------------------------------------------------------------------- */
    /*                              Render Component                              */
    /* -------------------------------------------------------------------------- */
    return (
      <>
        <MultilevelDrawer
          open={open}
          setOpen={setOpen}
          size="big"
          onClose={handleClose}
          customHeader={renderDrawerHeader()}
          customActions={renderDrawerActions()}
        >
          {activeStep === 0 && <>
            <Typography variant='body1'>
              {t('sharepoint:content.description.requestSharepointHints.introduction')}
            </Typography>
            <List>
              {implicationsAndComments.map(
                (listItem: {content: JSX.Element, icon?: JSX.Element}, index: number) => {
                  return (
                    <ListItem alignItems='flex-start' key={index}>
                      <ListItemIcon>
                        {listItem.icon ?? <TipsAndUpdatesRounded color='info'/>}
                      </ListItemIcon>
                      <ListItemText>
                        {listItem.content}
                      </ListItemText>
                    </ListItem>
                  )
                })
              }
            </List>
          </>}
          {activeStep === 1 && <>
            <Card
              variant='outlined'
            >
              <CardContent>
                <Typography variant='h5' mb={'1.5rem'}>
                  {t('item:content.heading.articleInfo')}
                </Typography>
                <Grid container>
                  <Form
                    editing={true}
                    formFields={requestSharepointArticleFormFields}
                    formObject={articleFormState}
                    onChange={value => setArticleFormState((prev: any) => ({...prev, ...value}))}
                  />
                </Grid>
                <Grid container>
                  <Grid item>
                    <Accordion style={{ marginTop: '20px' }}>
                      <AccordionSummary>
                        <ExpandMoreIcon />
                        <Typography variant="subtitle1">
                          {t('common:content.label.warehouse')}{' '}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Form
                          editing={true}
                          formFields={[
                            {
                              type: 'number',
                              key: 'minimumStorage',
                              label: t('item:content.label.minStorageAmount'),
                              errorMessage: t('item:feedback.error.minStorageInput') as string,
                            }
                          ]}
                          formObject={articleFormState}
                          onChange={value => setArticleFormState((prev: any) => ({...prev.article, value}))}
                        />
                      </AccordionDetails>
                    </Accordion>
                    <Accordion style={{ marginTop: '20px' }}>
                      <AccordionSummary>
                        <ExpandMoreIcon />
                        <Typography variant="subtitle1">
                          {t('common:content.label.dimensionWeight')}{' '}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography variant="subtitle2" sx={{mb:'30px'}}>
                          {t('common:feedback.info.dimensionWeight')}
                        </Typography>
                        <Form
                          editing={true}
                          formFields={dimensionsFormFields}
                          formObject={articleFormState}
                          onChange={value => setArticleFormState((prev: any) => ({...prev.article, value}))}
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
                <Grid container mt='2rem'>
                  <Grid item>
                    <FormControlLabel
                      id="purchasedPartCheckbox"
                      control={<Checkbox checked={isPurchasedPart} onChange={handleCheckboxChange} />}
                      label={t('common:content.label.purchasedPart')}
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="manufacturedPartCheckbox"
                          checked={isManufacturedPart}
                          onChange={handleManufactureCheckboxChange}
                        />
                      }
                      label={t('common:content.label.manufacturedPart')}
                    />
                  </Grid>
                  {isPurchasedPart && (
                    <Form
                      editing={true}
                      formFields={purchasedPartFormFields}
                      formObject={articleFormState}
                      onChange={value => setArticleFormState(value)}
                    />
                  )}
                  {isManufacturedPart && (
                    <>
                      <ProcessSelection
                        formState={{
                          state: processSelectionFormState,
                          setState: setProcessSelectionFormState,
                        }}
                      />
                    </>
                  )}
                </Grid>
              </CardContent>
              <Box padding='16px' display='flex' justifyContent='end'>
                <Button
                  variant='contained'
                  onClick={handleSaveAnonymizedRequestArticle}
                  disabled={savedAnonymizedArticle && !filePollingCompleted}
                >
                  {savedAnonymizedArticle
                    ? t('common:interaction.button.update')
                    : t('common:interaction.button.save')
                  }
                </Button>
              </Box>
            </Card>
            <Card variant='outlined' sx={{mt: '1rem'}}>
              <CardContent>
                <Typography variant='h5' mb={'1.5rem'}>
                  {t('item:content.heading.articleFiles')}
                </Typography>
                {savedAnonymizedArticle
                  ? filePollingCompleted
                    ? <>
                      <FilesTable
                        files={{
                          state: articleFormState.files,
                          setState: (files: FileInfo[]) => setArticleFormState((prev: any) => ({...prev, files: files}))
                        }}
                        onRemoveLocalFile={(file: FileInfo) => {
                          // remove local file from additionalFiles
                          setAdditionalFiles(prev => {
                            return prev.filter(additionalFile => additionalFile.name !== file.name)
                          })
                        }}
                        enableReplacementModal
                      />
                      <Typography variant='h6' mt='1.5rem' mb='0.5rem'>
                        {t('common:content.label.uploadAdditionalFiles')}
                      </Typography>
                      <FileInput inputState={{state: additionalFiles, setState: setAdditionalFiles}}
                      />
                      {/* additionalFiles.length > 0 &&
                        <InputFileList
                        files={additionalFiles}
                        enableEditMode={true}
                        onRemoveFile={(fileToRemove: FileInfo) => setAdditionalFiles(prev => prev.filter(file => file.name !== fileToRemove.name))}
                        sx={{
                          marginTop: '2rem',
                        }} noFilesInfo={""} onReUploadFile={function (file: FileInfo, url: string): Promise<void> {
                          throw new Error("Function not implemented.")
                        } } onDeleteFile={function (file: FileInfo): Promise<void> {
                          throw new Error("Function not implemented.")
                        } }                        />
                       */}
                    </>
                    : <Skeleton variant='rounded' animation='wave' sx={{width: '100%', height: '5.5rem'}} />
                  : <Alert severity='info'>
                      Das Bearbeiten der Dateien ist erst nach dem Speichern des anonymisierten Artikels möglich.
                    </Alert>
                }
              </CardContent>
              <Box padding='16px' display='flex' justifyContent='end'>
                <LoadingButton
                  loading={waitingOnUploadingFiles}
                  onClick={handleSaveAnonymizedArticleFiles}
                  variant='contained'
                  sx={{textTransform: 'unset', color: 'whitesmoke'}}
                >
                  {savedAnonymizedFiles
                    ? t('common:interaction.button.update')
                    : t('request:interaction.button.confirmAnonymization')
                  }
                </LoadingButton>
              </Box>
            </Card>
          </>}
          {activeStep === 2 && <>
            <Typography variant='h6' mb='1.2rem'>
              {t('sharepoint:content.heading.scopeAndDuration')}
            </Typography>
            <Grid container spacing={2} pl='2rem'>
              <Grid item xs={6}>
                <FieldLabel value={t('common:content.label.article')} />
                <List
                  sx={{
                    maxHeight: '20vh',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    border: '1px solid lightGrey',
                    borderRadius: '4px',
                  }}
                >
                  <ListItem key={getPKfromSelf(articleFormState.self)}>
                    <ListItemIcon><CheckBoxRounded color="disabled" /></ListItemIcon>
                    <ListItemText
                      sx={{
                        textWrap: 'wrap',
                        wordBreak: 'break-word',
                      }}
                      primary={getArticleRepresentation(articleFormState)}
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('sharepoint:content.description.articleSharepointHints.sharedArticlesHint')}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <NumberInputWithUnit
                    disabled={unlimitedShareDuration}
                    label={t('sharepoint:content.label.sharepointDuration')}
                    value={unlimitedShareDuration ? '' : (sharepointFormState.shareDuration ?? defaultShareDuration)}
                    limits={{minInput: 1, maxInput: 365}}
                    unit={t('common:content.label.days') as string}
                    onChange={
                      function (value: string | number): void {
                        setSharepointFormState((prev: any) => { return {
                          ...prev,
                          shareDuration: value
                        }})
                      }
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={unlimitedShareDuration} onChange={(e: any) => setUnlimitedShareDuration(e.target.checked)} />
                    }
                    label={t('sharepoint:interaction.checkbox.unlimitedSharepointDuration')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1">
                  {t('sharepoint:content.description.configureSharepoint.durationHint')}
                </Typography>
              </Grid>
            </Grid>
            <Typography variant='h6' mt='4rem;' mb='1.2rem'>
              {t('common:content.label.feedback')}
            </Typography>
            <Grid container spacing={2} pl='2rem'>
              <Grid item xs={6}>
                <SearchSelectInput
                  name={t('sharepoint:content.label.feedbackForm')}
                  value={sharepointFormState.feedbackForm}
                  options={feedbackFormOptions}
                  onChange={(input: any) => setSharepointFormState((prev: any) => {return {...prev, feedbackForm: input}})}
                  validation={false}
                  onAddOption={() => setOpenFormEditorDrawer(true)}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant='body1'>
                  {t('sharepoint:content.description.configureSharepoint.feedbackFormHint')}
                </Typography>
              </Grid>
              <Grid item xs={12} pb='1rem'>
                <Accordion disableGutters>
                  <AccordionSummary
                    expandIcon={<ExpandMoreRounded/>}
                  >
                    {t('sharepoint:content.heading.feedbackFormReview')}
                  </AccordionSummary>
                  <AccordionDetails>
                    {sharepointFormState.feedbackForm
                      ? renderFeedbackForm(feedbackFormStore.current.find(
                        (form: FeedbackForm) => form.self === sharepointFormState.feedbackForm.value))
                      : (
                        <Typography variant="body1" color='GrayText' textAlign='center' width='100%' mt='1.5rem' mb='1.5rem'>
                          {t('sharepoint:feedback.info.selectFeedbackForm')}
                        </Typography>
                      )
                    }
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </>}
          {(activeStep === steps.length - 1) && <>
            <Typography variant='h6' mb='1.2rem'>
              {t('sharepoint:content.heading.shareAutomatically')}
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1" ml='16px'>
                  {t('sharepoint:content.description.configureSharepoint.automaticShareHint')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <List>
                  {automaticSharesForm.map((automaticShareFieldObj: any, index: number) =>
                    renderAutomaticShareFieldObject(automaticShareFieldObj, index)
                  )}
                </List>
              </Grid>
            </Grid>
            <Typography variant='h6' mt='4rem;' mb='1.2rem'>
              {t('sharepoint:content.heading.shareManually')}
            </Typography>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1" ml='16px'>
                  {t('sharepoint:content.description.configureSharepoint.manualShareHint')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
              <List>
                  {manualSharesForm.map((manualShareFieldObj: any, index: number) =>
                    renderManualShareFieldObject(manualShareFieldObj, index)
                  )}
                </List>
              </Grid>
            </Grid>
          </>}
          {activeStep === steps.length && <>
            <Typography variant="h6" mb='1.2rem'>
              {t('sharepoint:content.heading.shareManually')}
            </Typography>
            <Grid container spacing={6}  pl='2rem' >
              {Object.values(manualSharesFormInput).map(
                (manualShare: any, index: number) => {
                  return (
                    <Grid item container spacing={1} key={index}>
                      <Grid item xs={12}>
                        <InfoField label={t('common:content.label.comment')} value={manualShare.comment} fullWidth/>
                      </Grid>
                      <Grid item xs={6}>
                        <InteractiveTextDisplay
                          type="link"
                          label={t('sharepoint:content.label.sharepointLink') as string}
                          value={manualShare.accessUrl}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InteractiveTextDisplay
                          type="password"
                          label={t('common:content.auth.password') as string}
                          value={manualShare.password}
                        />
                      </Grid>
                    </Grid>
                  )
                })}
              </Grid>
          </>}
        </MultilevelDrawer>
        {openFormEditorDrawer &&
          <FormEditorDrawer
            open={openFormEditorDrawer}
            setOpen={setOpenFormEditorDrawer}
            formTitleSuggestion={'Bulk prices for ' + getArticleRepresentation(articleFormState)}
            formFieldSuggestion={[
              ...selectedRequest.quantities.map(
                (quantity: number, index: number) => generateTextField(index, `${quantity} Pcs.`, true)
              ),
              generateAddFormFieldButton(),
            ]}
            onConfirm={handleAddFormOption}
          />
        }
        {openContactDrawer &&
          <ContactDrawer
            open={openContactDrawer}
            setOpen={setOpenContactDrawer}
            formState={{
              state: contactFormInput,
              setState: setContactFormInput,
            }}
            onConfirm={handleAddContactOption}
          />
        }
      </>
    )
  }